export const DTS_GRAPH = 'DTS_GRAPH';
export const DAS_GRAPH = 'DAS_GRAPH';

export const DAS_GRAPH_DATA = 'DAS_GRAPH_DATA';
export const DAS_GRAPH_DATA_SUCCESS = 'DAS_GRAPH_DATA_SUCCESS';
export const DAS_GRAPH_DATA_ERROR = 'DAS_GRAPH_DATA_ERROR';

export const DAS_GRAPH_TIME_DATA = 'DAS_GRAPH_TIME_DATA';
export const DAS_GRAPH_TIME_SUCCESS = 'DAS_GRAPH_TIME_SUCCESS';
export const DAS_GRAPH_TIME_ERROR = 'DAS_GRAPH_TIME_ERROR';


export const DAS_GRAPH_TIME_EVENT = 'DAS_GRAPH_TIME_EVENT';

export const DTS_GRAPH_TIME_EVENT = 'DTS_GRAPH_TIME_EVENT';


export const DTS_GRAPH_DATA = 'DTS_GRAPH_DATA';
export const DTS_GRAPH_DATA_SUCCESS = 'DTS_GRAPH_DATA_SUCCESS';
export const DTS_GRAPH_DATA_ERROR = 'DTS_GRAPH_DATA_ERROR';

export const DTS_GRAPH_TIME_DATA = 'DTS_GRAPH_TIME_DATA';
export const DTS_GRAPH_TIME_SUCCESS = 'DTS_GRAPH_TIME_SUCCESS';
export const DTS_GRAPH_TIME_ERROR = 'DTS_GRAPH_TIME_ERROR';


export const DAS_GRAPH_AMP_DATA = 'DAS_GRAPH_AMP_DATA';
export const DAS_GRAPH_AMP_SUCCESS = 'DAS_GRAPH_AMP_SUCCESS';
export const DAS_GRAPH_AMP_ERROR = 'DAS_GRAPH_AMP_ERROR';

export const DAS_GRAPH_AMP_TIME_DATA = 'DAS_GRAPH_AMP_TIME_DATA';

export const DAS_GRAPH_AMP_DEPTH_DATA = 'DAS_GRAPH_AMP_DEPTH_DATA';


export const DTS_GRAPH_TEMP_DATA = 'DTS_GRAPH_TEMP_DATA';
export const DTS_GRAPH_TEMP_SUCCESS = 'DTS_GRAPH_TEMP_SUCCESS';
export const DTS_GRAPH_TEMP_ERROR = 'DTS_GRAPH_TEMP_ERROR';


export const DTS_GRAPH_TEMP_TIME_DATA = 'DTS_GRAPH_TEMP_TIME_DATA';

export const DTS_GRAPH_TEMP_DEPTH_DATA = 'DTS_GRAPH_TEMP_DEPTH_DATA';

export const DTS_GRAPH_INJECTION_TEMP_DATA = 'DTS_GRAPH_INJECTION_TEMP_DATA';
export const DTS_GRAPH_INJECTION_TEMP_SUCCESS = 'DTS_GRAPH_INJECTION_TEMP_SUCCESS';
export const DTS_GRAPH_INJECTION_TEMP_ERROR = 'DTS_GRAPH_INJECTION_TEMP_ERROR';


export const DAS_GRAPH_INJECTION_AMP_DATA = 'DAS_GRAPH_INJECTION_AMP_DATA';
export const DAS_GRAPH_INJECTION_AMP_SUCCESS = 'DAS_GRAPH_INJECTION_AMP_SUCCESS';
export const DAS_GRAPH_INJECTION_AMP_ERROR = 'DAS_GRAPH_INJECTION_AMP_ERROR';

export const DTS_GRAPH_LEAK_TEMP_DATA = 'DTS_GRAPH_LEAK_TEMP_DATA';
export const DTS_GRAPH_LEAK_TEMP_SUCCESS = 'DTS_GRAPH_LEAK_TEMP_SUCCESS';
export const DTS_GRAPH_LEAK_TEMP_ERROR = 'DTS_GRAPH_LEAK_TEMP_ERROR';


export const DAS_GRAPH_LEAK_AMP_DATA = 'DAS_GRAPH_LEAK_AMP_DATA';
export const DAS_GRAPH_LEAK_AMP_SUCCESS = 'DAS_GRAPH_LEAK_AMP_SUCCESS';
export const DAS_GRAPH_LEAK_AMP_ERROR = 'DAS_GRAPH_LEAK_AMP_ERROR';

export const DTS_GRAPH_MICROSEISMIC_TEMP_DATA = 'DTS_GRAPH_MICROSEISMIC_TEMP_DATA';
export const DTS_GRAPH_MICROSEISMIC_TEMP_SUCCESS = 'DTS_GRAPH_MICROSEISMIC_TEMP_SUCCESS';
export const DTS_GRAPH_MICROSEISMIC_TEMP_ERROR = 'DTS_GRAPH_MICROSEISMIC_TEMP_ERROR';

export const DAS_GRAPH_MICROSEISMIC_AMP_DATA = 'DAS_GRAPH_MICROSEISMIC_AMP_DATA';
export const DAS_GRAPH_MICROSEISMIC_AMP_SUCCESS = 'DAS_GRAPH_MICROSEISMIC_AMP_SUCCESS';
export const DAS_GRAPH_MICROSEISMIC_AMP_ERROR = 'DAS_GRAPH_MICROSEISMIC_AMP_ERROR';

export const DAS_LIST_MICROSEISMIC_AMP_DATA = 'DAS_LIST_MICROSEISMIC_AMP_DATA';
export const DAS_LIST_MICROSEISMIC_AMP_SUCCESS = 'DAS_LIST_MICROSEISMIC_AMP_SUCCESS';
export const DAS_LIST_MICROSEISMIC_AMP_ERROR = 'DAS_LIST_MICROSEISMIC_AMP_ERROR';

export const DTS_LIST_INJECTION_TEMP_DATA = 'DTS_LIST_INJECTION_TEMP_DATA';
export const DTS_LIST_INJECTION_TEMP_SUCCESS = 'DTS_LIST_INJECTION_TEMP_SUCCESS';
export const DTS_LIST_INJECTION_TEMP_ERROR = 'DTS_LIST_INJECTION_TEMP_ERROR';

export const DAS_LIST_INJECTION_AMP_DATA = 'DAS_LIST_INJECTION_AMP_DATA';
export const DAS_LIST_INJECTION_AMP_SUCCESS = 'DAS_LIST_INJECTION_AMP_SUCCESS';
export const DAS_LIST_INJECTION_AMP_ERROR = 'DAS_LIST_INJECTION_AMP_ERROR';

export const DTS_LIST_ANOMALIES_TEMP_DATA = 'DTS_LIST_ANOMALIES_TEMP_DATA';
export const DTS_LIST_ANOMALIES_TEMP_SUCCESS = 'DTS_LIST_ANOMALIES_TEMP_SUCCESS';
export const DTS_LIST_ANOMALIES_TEMP_ERROR = 'DTS_LIST_ANOMALIES_TEMP_ERROR';

export const DAS_LIST_ANOMALIES_AMP_DATA = 'DAS_LIST_ANOMALIES_AMP_DATA';
export const DAS_LIST_ANOMALIES_AMP_SUCCESS = 'DAS_LIST_ANOMALIES_AMP_SUCCESS';
export const DAS_LIST_ANOMALIES_AMP_ERROR = 'DAS_LIST_ANOMALIES_AMP_ERROR';


export const DAS_GRAPH_SAND_AMP_DATA = 'DAS_GRAPH_SAND_AMP_DATA';
export const DAS_GRAPH_SAND_AMP_SUCCESS = 'DAS_GRAPH_SAND_AMP_SUCCESS';
export const DAS_GRAPH_SAND_AMP_ERROR = 'DAS_GRAPH_SAND_AMP_ERROR';

export const DTS_GRAPH_SAND_TEMP_DATA = 'DTS_GRAPH_SAND_TEMP_DATA';
export const DTS_GRAPH_SAND_TEMP_SUCCESS = 'DTS_GRAPH_SAND_TEMP_SUCCESS';
export const DTS_GRAPH_SAND_TEMP_ERROR = 'DTS_GRAPH_SAND_TEMP_ERROR';

export const DTS_COUNT_GRAPH_INJECTION_TEMP_DATA = 'DTS_COUNT_GRAPH_INJECTION_TEMP_DATA';
export const DTS_COUNT_GRAPH_INJECTION_TEMP_SUCCESS = 'DTS_COUNT_GRAPH_INJECTION_TEMP_SUCCESS';
export const DTS_COUNT_GRAPH_INJECTION_TEMP_ERROR = 'DTS_COUNT_GRAPH_INJECTION_TEMP_ERROR';

export const DAS_COUNT_GRAPH_INJECTION_AMP_DATA = 'DAS_COUNT_GRAPH_INJECTION_AMP_DATA';
export const DAS_COUNT_GRAPH_INJECTION_AMP_SUCCESS = 'DAS_COUNT_GRAPH_INJECTION_AMP_SUCCESS';
export const DAS_COUNT_GRAPH_INJECTION_AMP_ERROR = 'DAS_COUNT_GRAPH_INJECTION_AMP_ERROR';

export const DTS_COUNT_GRAPH_ANOMALIES_LEAK_TEMP_DATA = 'DTS_COUNT_GRAPH_ANOMALIES_LEAK_TEMP_DATA';
export const DTS_COUNT_GRAPH_ANOMALIES_LEAK_TEMP_SUCCESS = 'DTS_COUNT_GRAPH_ANOMALIES_LEAK_TEMP_SUCCESS';
export const DTS_COUNT_GRAPH_ANOMALIES_LEAK_TEMP_ERROR = 'DTS_COUNT_GRAPH_ANOMALIES_LEAK_TEMP_ERROR';

export const DAS_COUNT_GRAPH_ANOMALIES_LEAK_AMP_DATA = 'DAS_COUNT_GRAPH_ANOMALIES_LEAK_AMP_DATA';
export const DAS_COUNT_GRAPH_ANOMALIES_LEAK_AMP_SUCCESS = 'DAS_COUNT_GRAPH_ANOMALIES_LEAK_AMP_SUCCESS';
export const DAS_COUNT_GRAPH_ANOMALIES_LEAK_AMP_ERROR = 'DAS_COUNT_GRAPH_ANOMALIES_LEAK_AMP_ERROR';

export const DTS_COUNT_GRAPH_ANOMALIES_SAND_TEMP_DATA = 'DTS_COUNT_GRAPH_ANOMALIES_SAND_TEMP_DATA';
export const DTS_COUNT_GRAPH_ANOMALIES_SAND_TEMP_SUCCESS = 'DTS_COUNT_GRAPH_ANOMALIES_SAND_TEMP_SUCCESS';
export const DTS_COUNT_GRAPH_ANOMALIES_SAND_TEMP_ERROR = 'DTS_COUNT_GRAPH_ANOMALIES_SAND_TEMP_ERROR';

export const DAS_COUNT_GRAPH_ANOMALIES_SAND_AMP_DATA = 'DAS_COUNT_GRAPH_ANOMALIES_SAND_AMP_DATA';
export const DAS_COUNT_GRAPH_ANOMALIES_SAND_AMP_SUCCESS = 'DAS_COUNT_GRAPH_ANOMALIES_SAND_AMP_SUCCESS';
export const DAS_COUNT_GRAPH_ANOMALIES_SAND_AMP_ERROR = 'DAS_COUNT_GRAPH_ANOMALIES_SAND_AMP_ERROR';