/* eslint-disable react/prop-types */
import { React, useState } from 'react';
import { Row, Col, Typography, Button, message, Tooltip } from 'antd';
import { DownloadOutlined, SaveOutlined } from '@ant-design/icons';
import CsvDownloader from 'react-csv-downloader';

import axios from 'axios';
import { datetimeCovertertolocal } from '../../datetime-converter';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import { useAuth } from '../../Context';

export function BatchDownloader(props) {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const envstrgebatch = process.env.REACT_APP_BATCH_SIZE == undefined || process.env.REACT_APP_BATCH_SIZE == 'undefined' ? 1000 : process.env.REACT_APP_BATCH_SIZE;
	const BATCH_SIZE = localStorage.getItem(`${dashboardtype.toUpperCase()}_BATCH_SIZE`) == 'null' ?  parseInt(envstrgebatch) : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_BATCH_SIZE`));

	useAuth();
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const TOTAL = props.total;
	const BATCHES = TOTAL / BATCH_SIZE;
	const batches = [];
    
	let startIndex = 1;
	let index = 0;
    
	for (let i=0; i< BATCHES; i++) {
		let start = startIndex;
		let end = (startIndex + BATCH_SIZE-1);
      
		end = (end >= TOTAL) ? TOTAL : end;
      
		let batch = { index: index, start: start, end: end, fetched: false };
  
		batches.push(batch);
      
		startIndex += BATCH_SIZE;
		index++;
	}
  
	return <>
		<Row gutter={16} style={{
			padding: '5px',
			marginTop: '10px',
			maxHeight: '250px',
			overflowY: 'scroll'
		}}>
			<Col span={24}>
				<Row style={{
					backgroundColor: '#d3d3d3',
					alignItems: 'center',
					marginTop: '5px',
					marginBottom: '5px',
					padding: '8px'
				}}>
					{batches.map((batch, index) =>
						<>
							<Col span={22} style={{ marginBottom: '6px' }}>
								<Typography>{langdata && langdata.Batch ? langdata.Batch:'Batch'} {index+1} : {langdata && langdata.Rows ? langdata.Rows:'Rows'} {batch.start} {langdata && langdata.to ? langdata.to:'to'} {batch.end}</Typography>
							</Col>
							<Col span={2} style={{ marginBottom: '6px' }}>
								<BatchDownloaderItem index={index} batch={batch} url={props.url} title={langdata && langdata.DownloadReport ? langdata.DownloadReport:'Download Report'}/>
							</Col>
						</>
					)}
				</Row>
			</Col>
		</Row>
	</>;
}
  
export function BatchDownloaderItem(props) {
	const url = props.url ? props.url : null;
	const [loading, setLoading] = useState(false);
	const [fetched, setFetched] = useState(false);
	const [data, setData] = useState({});

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	
	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));
  
	const envstrgebatch = process.env.REACT_APP_BATCH_SIZE == undefined || process.env.REACT_APP_BATCH_SIZE == 'undefined' ? 1000 : process.env.REACT_APP_BATCH_SIZE;
	const BATCH_SIZE = localStorage.getItem(`${dashboardtype.toUpperCase()}_BATCH_SIZE`) == 'null' ?  parseInt(envstrgebatch) : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_BATCH_SIZE`));

	useAuth();
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const companyType = localStorage.getItem(`${dashboardtype.toUpperCase()}_PIPELINE_NAME`).toLowerCase();
	//const companyType =  'iocl';

	const columns = [
		{
			id: 'id',
			displayName: langdata && langdata.ID ? langdata.ID: 'ID'
		}, 
		{
			id: 'pipelineChainage',
			displayName: langdata && langdata.PipelineChainage ? langdata.PipelineChainage:  'Pipeline Chainage'
		},
		{
			id: 'device_id',
			displayName: langdata && langdata.Device ? langdata.Device: 'Device'
		},
		{
			id: 'datetime',
			displayName: langdata && langdata.DateTime ? langdata.DateTime:  'Date Time'
		}, 
		{
			id: 'vac2',
			displayName: companyType == 'iocl' ?  langdata && langdata.ONPSPAC ? langdata.ONPSPAC : 'ON PSP-AC' :  langdata && langdata.ACPICKUPV ? langdata.ACPICKUPV:  'AC PICKUP (V)'
		},
		{
			id: 'vdc2',
			displayName: companyType == 'iocl' ?  langdata && langdata.ONPSPDC ? langdata.ONPSPDC : 'ON PSP-DC' : langdata && langdata.PSPV ? langdata.PSPV:  'PSP (V)'
		},
		{
			id: 'vac1',
			displayName: companyType == 'iocl' ?  langdata && langdata.OFFPSPDC ? langdata.OFFPSPDC : 'OFF PSP-DC' : langdata && langdata.CASINGACPICKUPV ? langdata.CASINGACPICKUPV:  'CASING AC PICKUP (V)'
		},
		{
			id: 'vdc1',
			displayName: companyType == 'iocl' ?  langdata && langdata.CurrentACmA ? langdata.CurrentACmA : 'Current AC mA' : langdata && langdata.CSPV ? langdata.CSPV:  'CSP (V)'
		}, 
		{
			id: 'vbat',
			displayName: langdata && langdata.BatteryV ? langdata.BatteryV:  'Battery (V)'
		},
		{
			id: 'current',
			displayName: companyType == 'iocl' ?  langdata && langdata.CurrentDCmA ? langdata.CurrentDCmA : 'Current DC mA' : langdata && langdata.Current ? langdata.Current:   'Current'
		}, 
		{
			id: 'temp',
			displayName: langdata && langdata.Temp ? langdata.Temp:  'Temp'
		},
		{
			id: 'anomaly',
			displayName:  companyType == 'iocl' ? langdata && langdata.PSPType ? langdata.PSPType : 'PSP Type' : langdata && langdata.Anomaly ? langdata.Anomaly: 'Anomaly'
		},
		{
			id: 'systemStatus',
			displayName: langdata && langdata.SystemStatus ? langdata.SystemStatus: 'System Status'
		},
		{
			id: 'batteryStatus',
			displayName: langdata && langdata.BatteryStatus ? langdata.BatteryStatus:  ' Battery Status'
		},
		{
			id: 'batteryReplacedOn',
			displayName: langdata && langdata.BatteryReplacedOn ? langdata.BatteryReplacedOn:  'Battery Replaced On'
		},
		{
			id: 'hardwareConfigSyncedDate',
			displayName: langdata && langdata.HardwareConfigSyncedDate ? langdata.HardwareConfigSyncedDate: 'Hardware Config Synced Date'
		}
	];
  
	const fetchData = (index) => {
		setLoading(true);
  
		let skip =  (index>=1) ? (BATCH_SIZE * index) : (0);
  
		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url.base + `?$limit=${BATCH_SIZE}&$skip=${skip}&` + url.params,
			timeout: extendTimeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};
      
		axios.request(config) .then((response) => {
			if (response && response.data) {
				let datas = [];

				if (response.data && response.data.data) {
					response.data.data.map( (item) => {

						let systemStatus = item.smarttlp.systemStatus;
						if(systemStatus) {
							for (let i = 0; i < systemStatus.length; i++) {
								if(systemStatus.includes(',')) {
									systemStatus = systemStatus.replace(',', ' | ');
								}
							}

							if(systemStatus.includes('vdc2')) {
								companyType == 'iocl' ?
									systemStatus = systemStatus.replace('vdc2', 'ON PSP-DC')
									:
									systemStatus = systemStatus.replace('vdc2', 'PSP');
							}
							if(systemStatus.includes('vdc1')) {
								companyType == 'iocl' ?
									systemStatus = systemStatus.replace('vdc1', 'Current AC mA')
									:
									systemStatus = systemStatus.replace('vdc1', 'CSP');
							}
							if(systemStatus.includes('vac1')) {
								companyType == 'iocl' ?
									systemStatus = systemStatus.replace('vac1', 'OFF PSP-DC' )
									:
									systemStatus = systemStatus.replace('vac1', 'CASING AC PICKUP');
							}
							if(systemStatus.includes('vac2')) {
								companyType == 'iocl' ?
									systemStatus = systemStatus.replace('vac2', 'ON PSP-AC' )
									:
									systemStatus = systemStatus.replace('vac2', 'AC PICKUP');
							}
						}

						let data = {};

						const datetime = datetimeCovertertolocal(item.datetime);
						const syncdatetime = datetimeCovertertolocal(item.smarttlp && item.smarttlp.lastSync ? item.smarttlp.lastSync : null);
						const batteryReplacedDateTime = datetimeCovertertolocal(item.batteryReplacedDate);
						const hardwareConfigSyncedDateTime = datetimeCovertertolocal(item.hardwareConfigSyncedDate);

						const newdate = datetime.localdate + ' ' +  datetime.localtime;
						const syncdate = syncdatetime.localdate + ' ' +  syncdatetime.localtime;
						const batteryReplaced = batteryReplacedDateTime.localdate + ' ' + batteryReplacedDateTime.localtime;
						const hardwareConfigSynced = hardwareConfigSyncedDateTime.localdate + ' ' + hardwareConfigSyncedDateTime.localtime;

						const devicedatetime = datetimeCovertertolocal(item ?  item.datetime : '<Not Set>');
						const newdevicedate = devicedatetime.localdate + ' ' +  devicedatetime.localtime;

              
						data.id = item.id ? parseInt(item.id) : 0;
						data.tlpId = item.tlpId ? item.tlpId : 0;
						data.pipelineChainageId = item.pipelineChainageId ? item.pipelineChainageId : 0;
						data.pipelineChainage = item.smarttlp && item.smarttlp.pipelineChainage ? parseFloat(item.smarttlp.pipelineChainage)/1000 : 0;
						data.device_id = item.device_id ? item.device_id : 0;
						data.vac1 = item.vac1 ? parseFloat(item.vac1).toFixed(4) : 0;
						data.vac2 = item.vac2 ? parseFloat(item.vac2).toFixed(4) : 0;
						data.vdc1 = item.vdc1 ? parseFloat(item.vdc1).toFixed(4) : 0;
						data.vdc2 = item.vdc2 ? parseFloat(item.vdc2).toFixed(4) : 0;
						data.temp = item.temp ? parseFloat(item.temp).toFixed(4) : 0;
						data.vbat = item.vbat ? parseFloat(item.vbat).toFixed(4) : 0;
						data.current = item.current ? parseFloat(item.current).toFixed(2) : 0;
						data.anomaly =  companyType == 'iocl' ? item.anomaly ? 'OFF PSP' : 'ON PSP' : item.anomaly ? item.anomaly : false;
						data.datetime = item.datetime ? newdate : '-';
						data.lastSync = item.smarttlp && item.smarttlp.lastSync ? syncdate : '-';
						data.systemStatus = systemStatus ? systemStatus : 'Normal' ;
						data.batteryStatus = item.smarttlp && item.smarttlp.batteryStatus;
						data.batteryReplacedOn = item.smarttlp && item.smarttlp.batteryReplacedDate ? batteryReplaced : '-';
						data.hardwareConfigSyncedDate = item.smarttlp && item.smarttlp.hardwareConfigSyncedDate ? hardwareConfigSynced : '-';
						data.datetime = item.datetime ? newdevicedate : '-';

						if(item.smarttlp && item.smarttlp.secondVoltage === false) {
							data.vdc1 = 'NA';
							data.vac1 = 'NA';
						}

						datas.push(data);
					});
  
					setFetched(true);
					setData(datas);
					setLoading(false);
				} else {
					setFetched(true);
					setData([]);
					setLoading(false);   
				}
			} else {
				setFetched(false);
				setData([]);
				setLoading(false);
			}
		}).catch((error) => {
			console.log(error);
        
			setFetched(false);
			setData([]);
			setLoading(false);
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};
  
	return <>
		{ props && props.batch && fetched == false ? (
			<Tooltip title={ langdata && langdata.FetchdatafromOneView ? langdata.FetchdatafromOneView: 'Fetch data from OneView'}>
				<Button type="primary" shape="circle" loading = {loading} icon={<DownloadOutlined />} onClick={() => fetchData(props.index)}></Button>
			</Tooltip>
		) : (
			<>
				<CsvDownloader datas={data} filename={`${langdata && langdata.cpsreport ? langdata.cpsreport : 'cps-report'}-${props.index}`} extension=".csv" columns={columns}> 
					<Tooltip title={ langdata && langdata.Downloadtomycomputer ? langdata.Downloadtomycomputer:'Download to my computer'}>
						<Button type="primary" shape="circle" icon={<SaveOutlined />} style={{ backgroundColor: '#008000', border: '1px solid #008000' }}></Button>
					</Tooltip>
				</CsvDownloader>
			</>
		)}
      
	</>;
}