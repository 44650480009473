import { Avatar, Breadcrumb, Button, Card, Col, DatePicker, Divider, Empty, Form, Input, message, Modal, Row, Select, Slider, Space, Spin, Switch, Tooltip, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useReducer, useState } from 'react';
import { AreaChartOutlined, HomeOutlined, FullscreenOutlined, FullscreenExitOutlined, FilterOutlined, ReloadOutlined } from '@ant-design/icons';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
// import * as d3 from 'd3';
// import Plot from 'react-plotly.js';
import { useAuth } from '../../Context';
import { useLocation, useNavigate } from 'react-router-dom';
import Plot from 'react-plotly.js';
import dayjs from 'dayjs';
import moment from 'moment';
import { FRACTURING_LOCATION_DATA, FRACTURING_LOCATION_DATA_ERROR, FRACTURING_LOCATION_DATA_SUCCESS, MICRO_SEISMIC_INGRSS_GRAPH_CONFIG, MICROSEISMIC_GRAPH } from './constants';
import microseismicData1 from './reducer';
import axios from 'axios';
import { useFormik } from 'formik';

const { Title } = Typography;
const { RangePicker } = DatePicker;

// const unpack = (rows, key) => rows.map(row => row[key]);

export default function MicroseismicMonitoring () {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const { wellinfo, getWellInfo, wellzoneinfo,getWellZoneInfo,  wellchanneldata, getWellChannels,  wellFractureData, getWellFracture, getAllEvents, eventsData} = useAuth();
	const { state } = useLocation();
	const navigate = useNavigate();

	// const [websocketdasdata, setWebsocketDASData] =useState({});
	const [fullsize, setfullSize] = useState(false);
	const [graph, setGraph] = useState(null);
	const [spin, setSpin] = useState(false);
	const [wellId, setWellId] = useState();
	const [dassocketurl, setDasSocketUrl] = useState(null);
	const [dtssocketurl, setDtsSocketUrl] = useState(null);
	const [depthRangeFrom, setDepthRangeFrom] = useState(null);
	const [depthRangeTo, setDepthRangeTo] = useState(null);

	const [zoneId, setZoneId] = useState(null);
	const [zonefrom, setZoneFrom] = useState(null);
	const [zoneto, setZoneTo] = useState(null);
	const [startDate, setStartDate] = useState(moment.utc(new Date().setHours(0,0,0,0)).subtract({'days' : 30}).toISOString());
	const [endDate, setEndDate] = useState(new Date().toISOString());
	const [channelId, setChannelId] = useState(null);
	const [fractureId, setFractureId] = useState();

	const [wellFrom, setWellFrom] = useState(null);
	const [wellTo, setWellTo] = useState(null);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [drawerDepthFrom, setDrawerDepthFrom] = useState();
	const [drawerDepthTo, setDrawerDepthTo] = useState();

	const initalState = {graphData:[], loading: false, error: false, updateData : {},  fracturedata:[], graphConfig:{}};
	const [microSeismic, dispatch] = useReducer(microseismicData1, initalState);

	const [dasSocket, setDasSocket] = useState();

	const [selectdActivity, setSelectedActivity] = useState();
	const [channelAndPidsData, setChannelAndPidsData] = useState();
	const [trainSystem, setTrainSystem] = useState();
	const[dtsClickableData, setDTSClickableData] = useState();
	const [isDTSDataModalOpen, setIsDTSDataModalOpen] = useState(false);	
	const [graphScroll, setGraphScroll] = useState(false);

	let tempWellId;
	let tempdasurl;

	let tempstartDepth;
	let tempendDepth;
	let tempchannelId;


	// let temppressurl;
	// let temppressEnabled;
	// let tempdtsEnabled;
	let dasEnabled1 =false;
	
	let socketDAS = null;

	const allevents = [];
	eventsData && eventsData.map((item) => {
		allevents.push({
			value : item.id,
			label: item.eventType
		});
	});

	const welldata =[];

	wellinfo && wellinfo.map((item,index) => {

		if(item&&item.microseismicconfig){
			welldata.push({
				value : item.id,
				label : item.name.toUpperCase(),
				startDepth : item.startDepth,
				endDepth : item.endDepth,
				dasip: item && item.microseismicconfig &&item.microseismicconfig.dasip,
				dasport : item && item.microseismicconfig &&item.microseismicconfig.dasport,
				dtsip: item && item.microseismicconfig &&item.microseismicconfig.dtsip,
				dtsport : item && item.microseismicconfig &&item.microseismicconfig.dtsport,
				dtsminth : item && item.microseismicconfig && item.microseismicconfig.tempThresholdsOverride ?  item.microseismicconfig && item.microseismicconfig.tempThresholds && item.microseismicconfig.tempThresholds.minThreshold : item.microseismicconfig && item.microseismicconfig.tempThresholds && item.microseismicconfig.tempThresholds.defaultminThreshold ,
				dtsmaxth : item && item.microseismicconfig && item.microseismicconfig.tempThresholdsOverride ?  item.microseismicconfig && item.microseismicconfig.tempThresholds && item.microseismicconfig.tempThresholds.maxThreshold : item.microseismicconfig && item.microseismicconfig.tempThresholds && item.microseismicconfig.tempThresholds.defaultmaxThreshold,
				dasminth : item && item.microseismicconfig && item.microseismicconfig.ampThresholdsOverride ?  item.microseismicconfig && item.microseismicconfig.ampThresholds && item.microseismicconfig.ampThresholds.minThreshold : item.microseismicconfig && item.microseismicconfig.ampThresholds && item.microseismicconfig.ampThresholds.defaultminThreshold,
				dasmaxth : item && item.microseismicconfig && item.microseismicconfig.ampThresholdsOverride ?  item.microseismicconfig && item.microseismicconfig.ampThresholds && item.microseismicconfig.ampThresholds.maxThreshold : item.microseismicconfig && item.microseismicconfig.ampThresholds && item.microseismicconfig.ampThresholds.defaultmaxThreshold,
				presminth :item && item.microseismicconfig && item.microseismicconfig.pressThresholdsOverride ?  item.microseismicconfig && item.microseismicconfig.pressThresholds && item.microseismicconfig.pressThresholds.minThreshold : item.microseismicconfig && item.microseismicconfig.pressThresholds && item.microseismicconfig.pressThresholds.defaultminThreshold,
				presmaxth : item && item.microseismicconfig && item.microseismicconfig.pressThresholdsOverride ?  item.microseismicconfig && item.microseismicconfig.pressThresholds && item.microseismicconfig.pressThresholds.maxThreshold : item.microseismicconfig && item.microseismicconfig.pressThresholds && item.microseismicconfig.pressThresholds.defaultmaxThreshold,
				pressip: item && item.microseismicconfig &&item.microseismicconfig.pressureip,
				pressport : item && item.microseismicconfig &&item.microseismicconfig.pressureport,
				pressEnabled : item.pressureEnabled,
				dtsEnabled : item.dtsEnabled,
				dasEnabled : item.dasEnabled
			});
			if (index === 0) {			
				tempWellId = item.id;
				tempstartDepth = item.startDepth;
				tempendDepth = item.endDepth;
				tempdasurl = `ws://${item && item.microseismicconfig &&item.microseismicconfig.dasip}:${item && item.microseismicconfig &&item.microseismicconfig.dasport}`;
				// tempdtsurl = `ws://${item && item.microseismicconfig &&item.microseismicconfig.dtsip}:${item && item.microseismicconfig &&item.microseismicconfig.dtsport}`;
				// tempdtsminth = item && item.tempThresholdsOverride ?  item.microseismicconfig && item.microseismicconfig.tempThresholds && item.microseismicconfig.tempThresholds.minThreshold : item.microseismicconfig && item.microseismicconfig.tempThresholds && item.microseismicconfig.tempThresholds.defaultminThreshold;
				// tempdtsmaxth = item && item.tempThresholdsOverride ?  item.microseismicconfig && item.microseismicconfig.tempThresholds && item.microseismicconfig.tempThresholds.maxThreshold : item.microseismicconfig && item.microseismicconfig.tempThresholds && item.microseismicconfig.tempThresholds.defaultmaxThreshold;
				// tempdasminth = item && item.ampThresholdsOverride ?  item.microseismicconfig && item.microseismicconfig.ampThresholds && item.microseismicconfig.ampThresholds.minThreshold : item.microseismicconfig && item.microseismicconfig.ampThresholds && item.microseismicconfig.ampThresholds.defaultminThreshold;
				// tempdasmaxth = item && item.ampThresholdsOverride ?  item.microseismicconfig && item.microseismicconfig.ampThresholds && item.microseismicconfig.ampThresholds.maxThreshold : item.microseismicconfig && item.microseismicconfig.ampThresholds && item.microseismicconfig.ampThresholds.defaultmaxThreshold;
				// temppresminth = item && item.pressThresholdsOverride ?  item.microseismicconfig && item.microseismicconfig.pressThresholds && item.microseismicconfig.pressThresholds.minThreshold : item.microseismicconfig && item.microseismicconfig.pressThresholds && item.microseismicconfig.pressThresholds.defaultminThreshold;
				// temppresmaxth = item && item.pressThresholdsOverride ?  item.microseismicconfig && item.microseismicconfig.pressThresholds && item.microseismicconfig.pressThresholds.maxThreshold : item.microseismicconfig && item.microseismicconfig.pressThresholds && item.microseismicconfig.pressThresholds.defaultmaxThreshold;
				// temppressurl = `ws://${item && item.microseismicconfig &&item.microseismicconfig.pressureip}:${item && item.microseismicconfig &&item.microseismicconfig.pressureport}`;
				// temppressurl = `ws://${item.pressureip}:${item.pressureport}`;
				// temppressEnabled = item.pressureEnabled;
				// tempdtsEnabled = item.dtsEnabled;
				dasEnabled1 = item.dasEnabled;
			}

		}
	});

	const [dasEnabled, setdasEnabled] = useState();

	useEffect(() => {
		setdasEnabled(dasEnabled1);
		setDepthRangeFrom(tempstartDepth);
		setDepthRangeTo(tempendDepth);
	},[dasEnabled1, tempstartDepth, tempendDepth]);


	const handleSetWell = (e , options) =>{		

		tempWellId=e;
		setWellId(e);
		// setWebsocketDASData([]);
		if(dasSocket){
			dasSocket.close();
		}
		
		setDasSocketUrl(`ws://${options.dasip}:${options.dasport}`);
		setDtsSocketUrl(`ws://${options.dtsip}:${options.dtsport}`);

		setDepthRangeFrom(options.startDepth);
		setDepthRangeTo(options.endDepth);
		setZoneId(null);
		setZoneFrom(null);
		setZoneTo(null);

		setWellFrom(options.startDepth);
		setWellTo(options.endDepth);
		setdasEnabled(options.dasEnabled);

		setDrawerDepthFrom(options.startDepth);
		setDrawerDepthTo(options.startDepth);
		
	};

	const channellist =[{
		value:null,label:'ALL'
	}];

	wellchanneldata && wellchanneldata.map((item,index)=>{
		channellist.push({
			value: item.id,
			label : item.name

		});
		if(index==0){
			
			tempchannelId =  item.id;
		}
	});
	

	const zonelist =[{
		value:null,label:'ALL',zoneFrom:wellFrom ,zoneTo:wellTo	}];

	wellzoneinfo && wellzoneinfo.map((item)=>{
		zonelist.push({
			value : item.id,
			label : item.zoneName.toUpperCase(),
			zoneFrom:item.zoneFrom,
			zoneTo:item.zoneTo
		});
	});

	const fracturelist =[];
	let tempFractureid;
	wellFractureData && wellFractureData.data.map((item, index) => {
		fracturelist.push({ value : item.id, label : item.name });
		if(index == 0) {
			tempFractureid = item.id;
		}
	});

	useEffect(() => {
		getAllEvents(authtoken);
		getWellFracture(authtoken, null);
		getWellInfo(authtoken);
		getWellZoneInfo(authtoken,wellId ? wellId : tempWellId);
		if(tempWellId || wellId){
			getWellChannels(authtoken, null,wellId ? wellId : tempWellId);
			loadFractureLocation();
		}


	},[wellId,dassocketurl,dtssocketurl,tempWellId, channelId, tempchannelId, startDate,endDate, fractureId]);


	const loadFractureLocation =()=>{

		dispatch({type:FRACTURING_LOCATION_DATA});

		let params='';

		if(wellId || tempWellId){
			params += `wellinfoId=${wellId ? wellId : tempWellId}`;
		}
		if(startDate){
			params += `&datetimeFrom=${startDate}`;
		}
		if(endDate){
			params += `&datetimeTo=${endDate}`;
		}
		if(channelId || tempchannelId){
			params += `&channelId=${channelId ? channelId : tempchannelId}`;
		}
		// if(status){
		// 	params += `status=${wellId ? wellId : tempWellId}`;
		// }
		// if(seismictypeId){
		// 	params += `&seismicTypeId=${seismictypeId}`;
		// }
		if(fractureId){
			params += `&id=${fractureId}`;
		}


		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/wellfracturingdata?${params}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({type:FRACTURING_LOCATION_DATA_SUCCESS, payload:response.data});
					
				}else{
					dispatch({type:FRACTURING_LOCATION_DATA_ERROR, payload:[]});
				}
			})
			.catch((error) => {
				dispatch({type:FRACTURING_LOCATION_DATA_ERROR, payload:[]});
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});
	};

	const webSocketDataDAS = () =>{
		// const socket1 = new WebSocket(dassocketurl ? dassocketurl : tempdasurl); // Replace with your WebSocket URL
		// socket1.close();
		if(socketDAS == null) {
			socketDAS = new WebSocket(dassocketurl ? dassocketurl : tempdasurl); // Replace with your WebSocket URL
			setDasSocket(socketDAS);
			setSpin(true);		
	
			socketDAS.onopen = () => {
				console.log('WebSocket connection opened');
				setSpin(true);
			};
	
			socketDAS.onmessage = (event) => {
				try {
					const newData = JSON.parse(event.data);
				
					// setWebsocketDASData(newData);
					dispatch({ type : MICROSEISMIC_GRAPH, payload : newData });
					setSpin(false);
	
				} catch (error) {

					setSpin(false);
					console.error('Error parsing data:', error);
				}
			};
	
			socketDAS.onerror = (error) => {
				setSpin(false);
				console.error('WebSocket error:', error);
			};
	
			socketDAS.onclose = () => {
			// setTimeout(setSpin(false), 5000);	
			// setTimeout(()=> {
			// 	webSocketDataDAS();
			// }, 10000);	
				setSpin(false);
				dispatch({ type : MICROSEISMIC_GRAPH, payload : [] });
				console.log('WebSocket connection closed');
			};		
	
			const handlePopState = () => {			
				socketDAS.close();
				dispatch({ type : MICROSEISMIC_GRAPH, payload : [] });
			};

			const handleUrlChange = () => {
				if (socketDAS) {
					socketDAS.close();
					dispatch({ type : MICROSEISMIC_GRAPH, payload : [] });
				}
			};
		
			window.addEventListener('popstate', handlePopState);

			const originalPushState = history.pushState;
			const originalReplaceState = history.replaceState;

			history.pushState = function (...args) {
				originalPushState.apply(this, args);
				handleUrlChange(); // Close socket on push
			};

			history.replaceState = function (...args) {
				originalReplaceState.apply(this, args);
				handleUrlChange(); // Close socket on replace
			};
		
			return () => {
				window.removeEventListener('popstate', handlePopState);
				socketDAS.close();
				dispatch({ type : MICROSEISMIC_GRAPH, payload : [] });
				history.pushState = originalPushState; // Restore original pushState
				history.replaceState = originalReplaceState; // Restore original replaceState
			};
		}
	};	

	useEffect(() => {

		if((dassocketurl || tempdasurl) && (dasEnabled == true || dasEnabled1 == true)){						

			webSocketDataDAS();
		}
	
	}, [dassocketurl,tempdasurl, wellId]);
	
	
	


	const [microseismicData, setMicroseismicData] = useState([]);

	microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.p_wave_array &&  microSeismic.fracturedata.s_p_arrivals.p_wave_array.map((item, index) => {

		if(index==0){

			microseismicData.push(
				{
					// type: 'scatter',
					// z:websocketdata.data,
					x: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.p_wave_array ? [microSeismic.fracturedata.s_p_arrivals.p_wave_array[index], microSeismic.fracturedata.s_p_arrivals.p_wave_array[index]] : [],
					y: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.p_wave_array ? [0, depthRangeTo] : [],
					// text: data && data.dtsData && data.dtsData.anomaly_type ? [data.dtsData.anomaly_type] : '', 
					text: 'p-arrivals',
					mode: 'lines',
					line: {
						color: 'blue',
						width: 2,
						dash: 'dot',
					},		
					name: 'p-arrivals', 		
					// name: data && data.dtsData && data.dtsData.anomaly_type ? data.dtsData.anomaly_type : '', // Legend entry name for scatter plot
					
							
					hoverlabel: {
						bgcolor: 'blue', // Set the tooltip background color
					// font: {
					//   color: 'white', // Set the text color
					// }
					}
				},
				{
					// type: 'scatter',
					// z:websocketdata.data,
					x: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.p_wave_array ? [microSeismic.fracturedata.s_p_arrivals.p_wave_array[index]] : [],
					y: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.p_wave_array ? [depthRangeFrom + 50, depthRangeTo] : [],
					text: 'p-arrivals', 
					mode: 'text',
					showlegend: false,	
			
					// name: data && data.dtsData && data.dtsData.anomaly_type ? data.dtsData.anomaly_type : '', // Legend entry name for scatter plot
					
							
					hoverlabel: {
						bgcolor: 'blue', // Set the tooltip background color
					// font: {
					//   color: 'white', // Set the text color
					// }
					}
				}
			);
			
		}else{

			microseismicData.push(
				{
					// type: 'scatter',
					// z:websocketdata.data,
					x: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.p_wave_array ? [microSeismic.fracturedata.s_p_arrivals.p_wave_array[index], microSeismic.fracturedata.s_p_arrivals.p_wave_array[index]] : [],
					y: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.p_wave_array ? [0, depthRangeTo] : [],
					// text: data && data.dtsData && data.dtsData.anomaly_type ? [data.dtsData.anomaly_type] : '', 
					text: 'p-arrivals',
					mode: 'lines',
					line: {
						color: 'blue',
						width: 2,
						dash: 'dot',
					},	
					showlegend: false,	
					name: 'p-arrivals', 		
					// name: data && data.dtsData && data.dtsData.anomaly_type ? data.dtsData.anomaly_type : '', // Legend entry name for scatter plot
					
							
					hoverlabel: {
						bgcolor: 'blue', // Set the tooltip background color
					// font: {
					//   color: 'white', // Set the text color
					// }
					}
				},
				// {
				// 	// type: 'scatter',
				// 	// z:websocketdata.data,
				// 	x: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.p_wave_array ? [microSeismic.fracturedata.s_p_arrivals.p_wave_array[index]] : [],
				// 	y: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.p_wave_array ? [depthRangeFrom + 50, depthRangeTo] : [],
				// 	text: 'p-arrivals', 
				// 	mode: 'text',	
				// 	showlegend: false,			
				// 	// name: data && data.dtsData && data.dtsData.anomaly_type ? data.dtsData.anomaly_type : '', // Legend entry name for scatter plot
					
							
				// 	hoverlabel: {
				// 		bgcolor: 'blue', // Set the tooltip background color
				// 	// font: {
				// 	//   color: 'white', // Set the text color
				// 	// }
				// 	}
				// }
			);

		}
		
	});

	
	microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.s_waves_array &&  microSeismic.fracturedata.s_p_arrivals.s_waves_array.map((item, index) => {

		if(index==0){
			microseismicData.push(
				{
					// type: 'scatter',
					// z:websocketdata.data,
					x: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.s_waves_array ? [microSeismic.fracturedata.s_p_arrivals.s_waves_array[index], microSeismic.fracturedata.s_p_arrivals.s_waves_array[index]] : [],
					y: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.s_waves_array ? [0, depthRangeTo] : [],
					text: 's-arrivals', 
					mode: 'lines',
					line: {
						color: '#e3b622',
						width: 2,
						dash: 'dot',
					},			
					name: 's-arrivals',	
					// name: data && data.dtsData && data.dtsData.anomaly_type ? data.dtsData.anomaly_type : '', // Legend entry name for scatter plot
						
								
					hoverlabel: {
						bgcolor: 'e3b622', // Set the tooltip background color
					// font: {
					//   color: 'white', // Set the text color
					// }
					}
				},
				{
					// type: 'scatter',
					// z:websocketdata.data,
					x: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.s_waves_array ? [microSeismic.fracturedata.s_p_arrivals.s_waves_array[index]] : [],
					y: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.s_waves_array ? [depthRangeFrom + 50, depthRangeTo] : [],
					text: 's-arrivals', 
					mode: 'text',
					showlegend: false,
					name: 's-arrivals',	

					// name: data && data.dtsData && data.dtsData.anomaly_type ? data.dtsData.anomaly_type : '', // Legend entry name for scatter plot
						
								
					hoverlabel: {
						bgcolor: '#e3b622', // Set the tooltip background color
					// font: {
					//   color: 'white', // Set the text color
					// }
					}
				}
			);
		}else{
			microseismicData.push(
				{
					// type: 'scatter',
					// z:websocketdata.data,
					x: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.s_waves_array ? [microSeismic.fracturedata.s_p_arrivals.s_waves_array[index], microSeismic.fracturedata.s_p_arrivals.s_waves_array[index]] : [],
					y: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.s_waves_array ? [0, depthRangeTo] : [],
					text: 's-arrivals', 
					mode: 'lines',
					line: {
						color: '#e3b622',
						width: 2,
						dash: 'dot',
					},
					showlegend: false,			
					name: 's-arrivals',	
					// name: data && data.dtsData && data.dtsData.anomaly_type ? data.dtsData.anomaly_type : '', // Legend entry name for scatter plot
						
								
					hoverlabel: {
						bgcolor: 'e3b622', // Set the tooltip background color
					// font: {
					//   color: 'white', // Set the text color
					// }
					}
				},
				// {
				// 	// type: 'scatter',
				// 	// z:websocketdata.data,
				// 	x: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.s_waves_array ? [microSeismic.fracturedata.s_p_arrivals.s_waves_array[index]] : [],
				// 	y: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.s_waves_array ? [depthRangeFrom + 50, depthRangeTo] : [],
				// 	text: 's-arrivals', 
				// 	mode: 'text',
				// 	showlegend: false,
				// 	// name: data && data.dtsData && data.dtsData.anomaly_type ? data.dtsData.anomaly_type : '', // Legend entry name for scatter plot
						
								
				// 	hoverlabel: {
				// 		bgcolor: '#e3b622', // Set the tooltip background color
				// 	// font: {
				// 	//   color: 'white', // Set the text color
				// 	// }
				// 	}
				// }
			);
		}
		
	});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [fullsize]);	

	useEffect(() => {
		// Fetch CSV data
		// d3.csv('https://raw.githubusercontent.com/plotly/datasets/master/finance-charts-apple.csv')
		// 	.then(rows => {

		const trace2 = {
			type: 'scatter',
			mode: 'lines',
			x: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.time,
			y: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals && microSeismic.fracturedata.s_p_arrivals.depth,
			line: { color: '#17BECF' },
			name: langdata && langdata.Amplitude ? langdata.Amplitude : 'Amplitude',
			text: langdata && langdata.Amplitude ? langdata.Amplitude : 'Amplitude',

		};
				
		setMicroseismicData([trace2]);
		// })
		// .catch(error => console.error(error));
	}, [microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.s_p_arrivals]);

	const bandPassPlotData = [{
		z: microSeismic && microSeismic.graphData && microSeismic.graphData.data,
		y: microSeismic && microSeismic.graphData && microSeismic.graphData.depths,
		x: microSeismic && microSeismic.graphData && microSeismic.graphData.times,
		// colorscale: customColorscale, // Use custom color scale
		// x: [1,2,3,4,5,6,7,8,9],
		colorscale: 'Jet',
		// colorbar: {
		//   title: 'Intensity',
		//   tickvals: [0, 0.25, 0.5, 0.75, 1],
		//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
		// },
		type: 'heatmap',
		// reversescale:true
		hovertemplate : 'Time : %{x} <br> Depth : %{y} <br> Amplitude : %{z} <extra></extra>',
		colorbar: {x: 1,   thickness: 10,
			// thicknessmode: 'pixels',
			// lenmode: 'fraction',
			outlinewidth: 0},
		orientation: graphScroll == true ? 'h' : '',

	}];

	const bandPassLayout = {
		// title: 'Flow Monitoring',
		title: {
			text: langdata && langdata.BandpassfilteredHeatmap ? langdata.BandpassfilteredHeatmap : 'Bandpass filtered Heatmap',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		fontWeight: 800,
		autosize: true,
		xaxis: { title: langdata && langdata.Time ? langdata.Time : 'Time' },
		yaxis: { 
			title: langdata && langdata.Depth ? langdata.Depth : 'Depth', 
			// autorange: 'reversed',  
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ],
			tick0: graphScroll == true ? 0 : null,           // Start the ticks at 0 (adjust as needed)
			dtick: graphScroll == true ? 50 : null,
		},
		height: graphScroll == true ? 10000 :0,
		// paper_bgcolor: 'transparent', // Remove background color of the entire chart
		// plot_bgcolor: 'transparent'   // Remove background color of the plot area
	};


	const layoutMicroseismic = {
		title: {
			text:  langdata && langdata.MicroseismicData ? langdata.MicroseismicData :  'Microseismic Data',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			type: 'date',
			title: langdata && langdata.Time ? langdata.Time : 'Time'
		},
		yaxis: {
			// autorange: 'reversed',  
			// range: [86.8700008333, 138.870004167],
			type: 'linear',
			title: langdata && langdata.Amplitude ? langdata.Amplitude : 'Amplitude',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ],
			tick0: graphScroll == true ? 0 : null,           // Start the ticks at 0 (adjust as needed)
			dtick: graphScroll == true ? 50 : null,
		},
		showlegend:false,
		autosize: true,
		height: graphScroll == true ? 10000 :0,

	};

	const dotData = [
		{
			type: 'scatter',
			x: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.fractureLocations && microSeismic.fracturedata.fractureLocations && microSeismic.fracturedata.fractureLocations.Depth,
			y: microSeismic && microSeismic.fracturedata && microSeismic.fracturedata.fractureLocations && microSeismic.fracturedata.fractureLocations && microSeismic.fracturedata.fractureLocations.Distance,
			mode: 'markers',
			transforms: [{
				type: 'filter',
				target: 'y',
				operation: '>',
			// value: 4
			}],
			name: langdata && langdata.DistanceandDepth ? langdata.DistanceandDepth :  'Distance and Depth', // Legend entry name for scatter plot
			marker: {
				size: 10, // Increase the size of the points
				color: 'blue', // Optional: Change point color if needed
			},
		//   hoverlabel: {
		//     bgcolor: 'white', // Set the tooltip background color
		//     // font: {
		//     //   color: 'white', // Set the text color
		//     // }
		//   }
		},
		{
			type: 'scatter',
			x: [0, 0], // Use a data point to ensure the vertical line is at 'Moe'
			y: [0, depthRangeTo], // Y range for the vertical line
			mode: 'lines',
			line: {
				color: 'red',
				width: 2,
			},
			name: langdata && langdata.OilWell ? langdata.OilWell : 'Oil Well', // Legend entry name for the vertical line
			hoverinfo: 'text' // Show only the text in the tooltip
		}
	];

	const dotLayout = {
		title:{
			text :  langdata && langdata.FracturelocationMapping ? langdata.FracturelocationMapping : 'Fracture location Mapping',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			title: langdata && langdata.Distancemfromthefiber ? langdata.Distancemfromthefiber : 'Distance (m) from the fiber',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [-depthRangeTo , depthRangeTo ] : [ -tempendDepth , tempendDepth ]
			// tickvals: [-2000, -1000, 0, 1000, 2000, 3000, 4000], // Ensure these match your x-axis values
			// ticktext: [-2000, -1000, 0, 1000, 2000, 3000, 4000] // Ensure these match your x-axis labels
		},
		yaxis: {
			title:  langdata && langdata.Depthm ? langdata.Depthm :  'Depth (m)',
			// range: [0, 10], // Adjust as necessary to ensure the vertical line is visible
			// autorange: 'reversed',  
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ]
		},
		legend: {
			x: 1, // Position legend horizontally
			y: 1, // Position legend vertically
			traceorder: 'normal',
			orientation: 'v' // Vertical orientation
		}
	};

	const handlefullSize = (size,graph) =>{
		setfullSize(size);
		setGraph(graph);
	};		
		
	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const handleRangefrom = (e) => {				
		if(e.target.value) {
			setDepthRangeFrom(parseInt(e.target.value));
			setDrawerDepthFrom(parseInt(e.target.value));

		} else {
			setDepthRangeFrom();
			setDrawerDepthFrom();
		}
	};

	const handleRangeTo = (e) => {
		if(e.target.value) {
			setDepthRangeTo(e.target.value);
			setDrawerDepthTo(e.target.value);

		} else {
			setDepthRangeTo();
			setDrawerDepthTo();
		}
	};		

	const handleSetZone = (e,options)=>{
		
		setZoneId(e);
		setZoneFrom(options.zoneFrom);
		setDepthRangeFrom(options.zoneFrom || options.zoneFrom == 0 ? options.zoneFrom : tempstartDepth);
		setZoneTo(options.zoneTo);
		setDepthRangeTo(options.zoneTo || options.zoneTo == 0 ? options.zoneTo : tempendDepth);

		setDrawerDepthFrom(options.zoneFrom || options.zoneFrom == 0 ? options.zoneFrom : tempstartDepth);
		setDrawerDepthTo(options.zoneTo || options.zoneTo == 0 ? options.zoneTo : tempendDepth);


	};

	const disabledRangeTime = (currentDate) => {
		if (!currentDate) {
			return {};
		}
		
		const now = moment();
		
		// Disable future time slots on the current date
		if (currentDate.isSame(now, 'day')) {
			return {
				disabledHours: () => {
					const currentHour = now.hour();
					// Disable hours after the current hour
					return Array.from({ length: 24 }, (_, i) => (i > currentHour ? i : null)).filter(i => i !== null);
				},
				// disabledMinutes: () => {
				// 	const currentHour = now.hour();
				// 	const currentMinute = now.minute();
				// 	// Disable minutes after the current minute in the current hour
				// 	if (currentHour === now.hour()) {
				// 		return Array.from({ length: 60 }, (_, i) => (i > currentMinute ? i : null)).filter(i => i !== null);
				// 	}
				// 	return [];
				// },
				// disabledSeconds: () => {
				// 	const currentHour = now.hour();
				// 	const currentMinute = now.minute();
				// 	const currentSecond = now.second();
				// 	// Disable seconds after the current second in the current minute
				// 	if (currentHour === now.hour() && currentMinute === now.minute()) {
				// 		return Array.from({ length: 60 }, (_, i) => (i > currentSecond ? i : null)).filter(i => i !== null);
				// 	}
				// 	return [];
				// },
			};
		}
		
		// For future dates, allow all hours, minutes, and seconds
		return {};
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	const handleSetDate = (e) =>{
		if(e) {
			setStartDate(moment.utc(new Date(e[0] && e[0].$d)).toISOString());
			setEndDate(moment.utc(new Date(e[1] && e[1].$d)).toISOString());  
		} else {
			setStartDate(null);
			setEndDate(null);
		}
	};

	const handleSetChannel = (value) => {
		setChannelId(value);
	};

	const handleSetFracture = (value) => {
		setFractureId(value);
	};

	const handleDetail = () => {
		navigate(( '/microseismic-details'),{ state: { data: {wellId:wellId ? wellId : tempWellId, channelId:channelId ? channelId : tempchannelId, fractureId : fractureId ? fractureId : tempFractureid, startDate:startDate, endDate:endDate}, path: 'microseismic-monitoring-visualization' } });
	};

	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
		setIsDTSDataModalOpen(false);
		setTrainSystem(null);
		formik.resetForm();
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		setIsDTSDataModalOpen(false);
		setTrainSystem(null);
		formik.resetForm();
	};

	const handleDepthChange = (value) => {
		setDrawerDepthFrom(value[0]);
		setDrawerDepthTo(value[1]);
	};

	const handleInputDepthRangefrom = (e) => {
		const startDepth = wellTo ? wellTo : tempstartDepth;
		const endDepth = wellFrom ? wellFrom : tempendDepth;
		if(parseInt(e.target.value) >= startDepth && parseInt(e.target.value) <= endDepth){
			setDrawerDepthFrom(parseInt(e.target.value));
			setDrawerDepthTo(drawerDepthTo ? drawerDepthTo : depthRangeTo);
		}
	};

	const handleInputDepthRangeTo = (e) => {
		const startDepth = wellTo ? wellTo : tempstartDepth;
		const endDepth = wellFrom ? wellFrom : tempendDepth;
		if(parseInt(e.target.value) >= startDepth && parseInt(e.target.value) <= endDepth){
			setDrawerDepthTo(parseInt(e.target.value));	
			setDrawerDepthFrom(drawerDepthFrom ? drawerDepthFrom : depthRangeFrom);
		}
	};

	useEffect(() => {
		let params = '';		
		if(wellId || tempWellId) {
			params += `wellinfoId=${wellId ? wellId : tempWellId}`;
		
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/microseismicconfig?${params}`,
				headers: { 
					Authorization:`Bearer ${authtoken}`	
				}
			};
		
			axios.request(config)
				.then((response) => {
					if(response.status == 200) {
						dispatch({ type : MICRO_SEISMIC_INGRSS_GRAPH_CONFIG, payload : response.data });
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	},[wellId, tempWellId]);


	const formik = useFormik({
		enableReinitialize: true,
		initialValues : {
			id : microSeismic.graphConfig && microSeismic.graphConfig.id,
			WellId : microSeismic.graphConfig && microSeismic.graphConfig.wellinfoId,
			minAmpThreshold : microSeismic.graphConfig && microSeismic.graphConfig.ampThresholds && microSeismic.graphConfig.ampThresholds.minThreshold,
			maxAmpThreshold : microSeismic.graphConfig && microSeismic.graphConfig.ampThresholds && microSeismic.graphConfig.ampThresholds.maxThreshold,
			minAmpSNThresholds : microSeismic.graphConfig && microSeismic.graphConfig.ampSNThresholds && microSeismic.graphConfig.ampSNThresholds.minThreshold,
			maxAmpSNThresholds : microSeismic.graphConfig && microSeismic.graphConfig.ampSNThresholds && microSeismic.graphConfig.ampSNThresholds.maxThreshold,
			minAmpFreqThresholds : microSeismic.graphConfig && microSeismic.graphConfig.ampFreqThresholds && microSeismic.graphConfig.ampFreqThresholds.minThreshold,
			maxAmpFreqThresholds : microSeismic.graphConfig && microSeismic.graphConfig.ampFreqThresholds && microSeismic.graphConfig.ampFreqThresholds.maxThreshold,
			minTempThreshold : microSeismic.graphConfig && microSeismic.graphConfig.tempThresholds && microSeismic.graphConfig.tempThresholds.minThreshold,
			maxTempThreshold : microSeismic.graphConfig && microSeismic.graphConfig.tempThresholds && microSeismic.graphConfig.tempThresholds.maxThreshold,
			minTempSNThresholds : microSeismic.graphConfig && microSeismic.graphConfig.tempSNThresholds && microSeismic.graphConfig.tempSNThresholds.minThreshold,
			maxTempSNThresholds : microSeismic.graphConfig && microSeismic.graphConfig.tempSNThresholds && microSeismic.graphConfig.tempSNThresholds.maxThreshold,
			minTempFreqThresholds : microSeismic.graphConfig && microSeismic.graphConfig.tempFreqThresholds && microSeismic.graphConfig.tempFreqThresholds.minThreshold,
			maxTempFreqThresholds : microSeismic.graphConfig && microSeismic.graphConfig.tempFreqThresholds && microSeismic.graphConfig.tempFreqThresholds.maxThreshold,
			minPressureThreshold : microSeismic.graphConfig && microSeismic.graphConfig.pressThresholds && microSeismic.graphConfig.pressThresholds.minThreshold,
			maxPressureThreshold : microSeismic.graphConfig && microSeismic.graphConfig.pressThresholds && microSeismic.graphConfig.pressThresholds.maxThreshold,
			minPressSNThresholds : microSeismic.graphConfig && microSeismic.graphConfig.pressSNThresholds && microSeismic.graphConfig.pressSNThresholds.minThreshold,
			maxPressSNThresholds : microSeismic.graphConfig && microSeismic.graphConfig.pressSNThresholds && microSeismic.graphConfig.pressSNThresholds.maxThreshold,
			minPressFreqThresholds : microSeismic.graphConfig && microSeismic.graphConfig.pressFreqThresholds && microSeismic.graphConfig.pressFreqThresholds.minThreshold,
			maxPressFreqThresholds : microSeismic.graphConfig && microSeismic.graphConfig.pressFreqThresholds && microSeismic.graphConfig.pressFreqThresholds.maxThreshold,
			ampThresholdsOverride : microSeismic.graphConfig && microSeismic.graphConfig.ampThresholdsOverride,
			tempThresholdsOverride : microSeismic.graphConfig && microSeismic.graphConfig.tempThresholdsOverride,
			pressThresholdsOverride : microSeismic.graphConfig && microSeismic.graphConfig.pressThresholdsOverride,
			ampSNThresholdsOverride : microSeismic.graphConfig && microSeismic.graphConfig.ampSNThresholdsOverride,
			tempSNThresholdsOverride : microSeismic.graphConfig && microSeismic.graphConfig.tempSNThresholdsOverride,
			pressSNThresholdsOverride : microSeismic.graphConfig && microSeismic.graphConfig.pressSNThresholdsOverride,
			ampFreqThresholdsOverride : microSeismic.graphConfig && microSeismic.graphConfig.ampFreqThresholdsOverride,
			tempFreqThresholdsOverride : microSeismic.graphConfig && microSeismic.graphConfig.tempFreqThresholdsOverride,
			pressFreqThresholdsOverride : microSeismic.graphConfig && microSeismic.graphConfig.pressFreqThresholdsOverride,
			defaultminAmpThreshold : microSeismic.graphConfig && microSeismic.graphConfig.ampThresholds && microSeismic.graphConfig.ampThresholds.defaultminThreshold,
			defaultmaxAmpThreshold : microSeismic.graphConfig && microSeismic.graphConfig.ampThresholds && microSeismic.graphConfig.ampThresholds.defaultmaxThreshold,
			defaultminAmpSNThresholds : microSeismic.graphConfig && microSeismic.graphConfig.ampSNThresholds && microSeismic.graphConfig.ampSNThresholds.defaultminThreshold,
			defaultmaxAmpSNThresholds : microSeismic.graphConfig && microSeismic.graphConfig.ampSNThresholds && microSeismic.graphConfig.ampSNThresholds.defaultmaxThreshold,
			defaultminAmpFreqThresholds : microSeismic.graphConfig && microSeismic.graphConfig.ampFreqThresholds && microSeismic.graphConfig.ampFreqThresholds.defaultminThreshold,
			defaultmaxAmpFreqThresholds : microSeismic.graphConfig && microSeismic.graphConfig.ampFreqThresholds && microSeismic.graphConfig.ampFreqThresholds.defaultmaxThreshold,
			defaultminTempThreshold : microSeismic.graphConfig && microSeismic.graphConfig.tempThresholds && microSeismic.graphConfig.tempThresholds.defaultminThreshold,
			defaultmaxTempThreshold : microSeismic.graphConfig && microSeismic.graphConfig.tempThresholds && microSeismic.graphConfig.tempThresholds.defaultmaxThreshold,
			defaultminTempSNThresholds : microSeismic.graphConfig && microSeismic.graphConfig.tempSNThresholds && microSeismic.graphConfig.tempSNThresholds.defaultminThreshold,
			defaultmaxTempSNThresholds : microSeismic.graphConfig && microSeismic.graphConfig.tempSNThresholds && microSeismic.graphConfig.tempSNThresholds.defaultmaxThreshold,
			defaultminTempFreqThresholds : microSeismic.graphConfig && microSeismic.graphConfig.tempFreqThresholds && microSeismic.graphConfig.tempFreqThresholds.defaultminThreshold,
			defaultmaxTempFreqThresholds : microSeismic.graphConfig && microSeismic.graphConfig.tempFreqThresholds && microSeismic.graphConfig.tempFreqThresholds.defaultmaxThreshold,
			defaultminPressureThreshold : microSeismic.graphConfig && microSeismic.graphConfig.pressThresholds && microSeismic.graphConfig.pressThresholds.defaultminThreshold,
			defaultmaxPressureThreshold : microSeismic.graphConfig && microSeismic.graphConfig.pressThresholds && microSeismic.graphConfig.pressThresholds.defaultmaxThreshold,
			defaultminPressSNThresholds : microSeismic.graphConfig && microSeismic.graphConfig.pressSNThresholds && microSeismic.graphConfig.pressSNThresholds.defaultminThreshold,
			defaultmaxPressSNThresholds : microSeismic.graphConfig && microSeismic.graphConfig.pressSNThresholds && microSeismic.graphConfig.pressSNThresholds.defaultmaxThreshold,
			defaultminPressFreqThresholds : microSeismic.graphConfig && microSeismic.graphConfig.pressFreqThresholds && microSeismic.graphConfig.pressFreqThresholds.defaultminThreshold,
			defaultmaxPressFreqThresholds : microSeismic.graphConfig && microSeismic.graphConfig.pressFreqThresholds && microSeismic.graphConfig.pressFreqThresholds.defaultmaxThreshold,
		},
		onSubmit: () => {
			
			const id = parseInt(formik.values.id);
			let data = JSON.stringify({
				'ampThresholds': {
					'maxThreshold': formik.values.maxAmpThreshold,
					'minThreshold': formik.values.minAmpThreshold,
					'defaultmaxThreshold': formik.values.defaultmaxAmpThreshold,
					'defaultminThreshold': formik.values.defaultminAmpThreshold
				},
				'ampThresholdsOverride': formik.values.ampThresholdsOverride ,
				'tempThresholds': {
					'maxThreshold': formik.values.maxTempThreshold,
					'minThreshold': formik.values.minTempThreshold,
					'defaultmaxThreshold': formik.values.defaultmaxTempThreshold,
					'defaultminThreshold': formik.values.defaultminTempThreshold
				},
				'tempThresholdsOverride': formik.values.tempThresholdsOverride ,
				// 'ampSNThresholds': {
				// 	'maxThreshold': formik.values.maxAmpSNThresholds,
				// 	'minThreshold': formik.values.minAmpSNThresholds,
				// 	'defaultmaxThreshold': formik.values.defaultmaxAmpSNThresholds,
				// 	'defaultminThreshold': formik.values.defaultminAmpSNThresholds
				// },
				// 'ampSNThresholdsOverride': formik.values.ampSNThresholdsOverride ,
				// 'tempSNThresholds': {
				// 	'maxThreshold': formik.values.maxTempSNThresholds,
				// 	'minThreshold': formik.values.minTempSNThresholds,
				// 	'defaultmaxThreshold': formik.values.defaultmaxTempSNThresholds,
				// 	'defaultminThreshold': formik.values.defaultminTempSNThresholds
				// },
				// 'tempSNThresholdsOverride': formik.values.tempSNThresholdsOverride ,
				'ampFreqThresholds': {
					'maxThreshold': formik.values.maxAmpFreqThresholds,
					'minThreshold': formik.values.minAmpFreqThresholds,
					'defaultmaxThreshold': formik.values.defaultmaxAmpFreqThresholds,
					'defaultminThreshold': formik.values.defaultminAmpFreqThresholds
				},
				'ampFreqThresholdsOverride': formik.values.ampFreqThresholdsOverride ,
				'tempFreqThresholds': {
					'maxThreshold': formik.values.maxTempFreqThresholds,
					'minThreshold': formik.values.minTempFreqThresholds,
					'defaultmaxThreshold': formik.values.defaultmaxTempFreqThresholds,
					'defaultminThreshold': formik.values.defaultminTempFreqThresholds
				},
				'tempFreqThresholdsOverride': formik.values.tempFreqThresholdsOverride ,
			});

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/microseismicconfig/${id}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					Authorization:`Bearer ${authtoken}`	
				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 || response.status == 201) {
						getWellInfo(authtoken);
						Modal.success({ title: langdata && langdata.Graphdetailssuccessfullyupdated ? langdata.Graphdetailssuccessfullyupdated : 'Graph details successfully updated.' });
						setIsModalOpen(false);
						setDepthRangeFrom(drawerDepthFrom);
						setDepthRangeTo(drawerDepthTo);
					} else {
						Modal.warning({ title: langdata && langdata.Unabletoupdategraphdetails ? langdata.Unabletoupdategraphdetails : 'Unable to update graph details' });

					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Unabletoupdategraphdetails ? langdata.Unabletoupdategraphdetails : 'Unable to update graph details' });
					}
				});
		}
	});			
	
	const depthMarks = {
		[wellFrom ? wellFrom : tempstartDepth] : langdata && langdata.Min ? langdata.Min : 'Min',
		[wellTo ? wellTo : tempendDepth] :langdata && langdata.Max ? langdata.Max : 'Max', 
		// [drawerDepthFrom] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{drawerDepthFrom}</span>},
		// [drawerDepthTo] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{drawerDepthTo}</span>},
	};	

	const ampMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 : langdata && langdata.Max ? langdata.Max : 'Max',
		// [formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold}</span>},
		// [formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold}</span>}
	};		

	
	const dasFrequencyMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 : langdata && langdata.Max ? langdata.Max : 'Max',
		// [formik.values.ampFreqThresholdsOverride == true ? formik.values.minAmpFreqThresholds : formik.values.defaultminAmpFreqThresholds] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampFreqThresholdsOverride == true ? formik.values.minAmpFreqThresholds : formik.values.defaultminAmpFreqThresholds}</span>} ,
		// [formik.values.ampFreqThresholdsOverride == true ? formik.values.maxAmpFreqThresholds : formik.values.defaultmaxAmpFreqThresholds] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampFreqThresholdsOverride == true ? formik.values.maxAmpFreqThresholds : formik.values.defaultmaxAmpFreqThresholds}</span>}
	};	

			

	const handleReset = () => {
		formik.resetForm();
		setDrawerDepthFrom(wellFrom ? wellFrom : tempstartDepth);
		setDrawerDepthTo(wellTo ? wellTo : tempendDepth);
	};	

	const handleDTSClick = (value, system) => {				
		setTrainSystem(system);
		setDTSClickableData( value.points[0]);
		setIsDTSDataModalOpen(true);
		if(system == 'dts'){
			setChannelAndPidsData({
				channel_id : microSeismic && microSeismic.graphData && microSeismic.graphData.channel_id,
				channel_name : microSeismic && microSeismic.graphData && microSeismic.graphData.channel_name,
				pids_id : microSeismic && microSeismic.graphData && microSeismic.graphData.pids_id,
				pids_name : microSeismic && microSeismic.graphData && microSeismic.graphData.pids_name,
				wellinfoName : microSeismic && microSeismic.graphData && microSeismic.graphData.well_name
			});
		} else {
			setChannelAndPidsData({
				channel_id : microSeismic && microSeismic.graphData && microSeismic.graphData.channel_id,
				channel_name : microSeismic && microSeismic.graphData && microSeismic.graphData.channel_name,
				pids_id : microSeismic && microSeismic.graphData && microSeismic.graphData.pids_id,
				pids_name : microSeismic && microSeismic.graphData && microSeismic.graphData.pids_name,
				wellinfoName : microSeismic && microSeismic.graphData && microSeismic.graphData.well_name
			});
		}
	};			
	
	const handleSelectEvent = (value) => {
		setSelectedActivity(value);
	};

	const handleNavigateSimulation = () => {
		navigate(( '/add-simulations'),{ state: {time : dtsClickableData && dtsClickableData.x, depth : Math.trunc(dtsClickableData && dtsClickableData.y ), 
			activity : selectdActivity, wellId : wellId ? wellId : tempWellId, system : trainSystem, channelAndPidsData : channelAndPidsData} });
	};
	
	const handleSelectGraphScroll = (value) => {
		setGraphScroll(value);
	};

	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={8}>
						<Row>
							<Space>
								<AreaChartOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.MicroseismicMonitoring ? langdata.MicroseismicMonitoring : 'Microseismic Monitoring'}</Title>
							<Space>&nbsp;&nbsp;</Space>
						</Row>
					</Col>
					<Col span={16}>
						<Row justify="end">
							<Col>
								<Tooltip placement="bottom" title={langdata && langdata.FilterbyWell ? langdata.FilterbyWell : 'Filter by well'}>
									<Space>
										<Select
											style={{ width: '180px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectWell ? langdata.SelectWell : 'Select Well'}
											optionFilterProp="children"
											value={wellId ? wellId : tempWellId}
											options={welldata}
											onSelect={(e, options)=>handleSetWell(e, options)}
										/>
									</Space>
									<Space>&nbsp;</Space>	
								</Tooltip>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyzone ? langdata.Filterbyzone : 'Filter by zone'}>
									<Space>
										<Select
											style={{ width: '180px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectZone ? langdata.SelectZone : 'Select Zone'}
											optionFilterProp="children"
											defaultValuevalue={null}
											value={zoneId}
											options={zonelist}
											onSelect={(e, options)=>handleSetZone(e, options)}
										/>
									</Space>
								</Tooltip>
								
								{dasEnabled == true ?
									<>
										<Space>&nbsp;</Space>
										<Tooltip placement="bottom" title={langdata && langdata.EnterDepthRange ? langdata.EnterDepthRange:'Enter Depth Range'}>
											<Space.Compact size="middle" style={{ width : '180px', margin:'5px'}}>
												<Input type='number' value={depthRangeFrom} max={zonefrom} min={zoneto}  placeholder={langdata && langdata.DepthRangeFrom ? langdata.DepthRangeFrom: 'Depth Range From'} onChange={handleRangefrom} />
												<Input type='number' value={depthRangeTo} max={zonefrom} min={zoneto}  placeholder={langdata && langdata.DepthRangeTo ? langdata.DepthRangeTo: 'Depth Range To'} onChange={handleRangeTo}/>
											</Space.Compact>
										</Tooltip>
										<Space>&nbsp;</Space>
										<Space>
											<Button style ={{margin:'5px'}} type='primary' onClick={showModal}><FilterOutlined /></Button>
										</Space>
									</>
									: null }								
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					// marginTop : '5px',
					// paddingLeft: '10px',
					// paddingRight: '10px',
					// background: '#FFFFFF',
					alignItems:'center',
				}}  >
					<Col span={18}>
						<Breadcrumb
							items={[
								{
									href: '/dashboard',
									title: <HomeOutlined />,
								},
								{
									title: ( state && state.path === 'well-visualization' ? <a onClick={()=>{navigate(-1);}}>{langdata && langdata.WellVisualization ? langdata.WellVisualization:'Well Visualization'}</a> :  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.WellVisualization ? langdata.WellVisualization:'Well Visualization'}</a>)
								},
								{
									title: `${langdata && langdata.MicroseismicMonitoring ? langdata.MicroseismicMonitoring : 'Microseismic Monitoring'}`,
								},
							]}
							// style={{ marginBottom : '10px'}}
						/>
					</Col>
					<Col span={6} style={{ display : 'flex', justifyContent : 'flex-end'}}>
						<Tooltip title={langdata && langdata.Graphlayout ? langdata.Graphlayout:'Graph layout'}>
							<Switch style={{backgroundColor: graphScroll == false ? '' : 'green'}} checkedChildren={langdata && langdata.Scroll ? langdata.Scroll:'Scroll'} unCheckedChildren={langdata && langdata.Fixed ? langdata.Fixed:'Fixed'} checked={graphScroll} onClick={handleSelectGraphScroll}/>
						</Tooltip>
					</Col>
				</Row>
				{dasEnabled == true ? 

					fullsize==false ?
						<>
							<Card style={{ minHeight: '500px', margin: '10px', padding : '5px' }}>
								<Row style={{
								// margin: '10px',
								// marginBottom : '0px',
								// marginTop : '10px',
								// paddingLeft: '10px',
								// paddingRight: '10px',
								// backgroundColor: '#ffffff',
									alignItems:'center',
								}} >
									<Col span={24} style={{ minHeight : '450px'}}>
										<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
											<button
												style={{ marginTop : '6px', marginRight : '1px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'bandpassfilteredHeatmap');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button>

											<p style={{marginTop:'-15px',marginLeft:'-15px', fontSize : '10px'}}
												// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
												className="wellInfo" >
												{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
												<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
												<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {microSeismic && microSeismic.graphData && microSeismic.graphData.channel_name} <br />
											</p>
											<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>
												<Plot
													data={bandPassPlotData}
													layout={ bandPassLayout}
													style={{ width: '100%', height: '450px', minHeight : '450px' }}
													onClick={(value) => {handleDTSClick(value, 'das');}}
													config={{ 
														responsive: true,
														// displayModeBar: true,
														displaylogo : false,
														modeBarButtonsToRemove: [
															'pan2d',
															'zoom2d',
															'select2d',
															'zoomIn2d',
															'zoomOut2d',
															'autoScale2d',
															'resetScale2d',
															'hoverClosestCartesian',
															'hoverCompareCartesian',
															'zoom3d',
															'pan3d',
															'resetViews3d',
															'hoverClosest3d',
															'hoverCompare3d',
															'orbitRotation',
															'tableRotation',
															'resetCameraDefault3d',
															'resetCameraLastSave3d',
															'lasso2d'
														],
	
													}}
												/>
											</Col>
										</Spin>
									</Col>
								</Row>
							</Card>
							<Row style={{ backgroundColor : '#ffffff', padding: '5px', marginLeft : '10px', marginRight : '10px'}}>
							
								<Col span={24} >
									<Row justify="end">
										<Col>
											<Tooltip placement="bottom" title={langdata && langdata.Filterbychannel ? langdata.Filterbychannel : 'Filter by channel'}>
												<Select
													style={{ width: '180px', margin:'5px'}}
													showSearch
													filterOption={filterOption}
													placeholder={langdata && langdata.SelectChannel ? langdata.SelectChannel : 'Select Channel'}
													optionFilterProp="children"
													defaultValuevalue={null}
													value={channelId ?  channelId : tempchannelId}													
													options={channellist}
													onSelect={(value)=>handleSetChannel(value)}
												/>
											</Tooltip>
											<Space>&nbsp;</Space>
											<Tooltip placement="bottom" title={langdata && langdata.Pleaseselectthedateandtime ? langdata.Pleaseselectthedateandtime :'Please select the date and time'}>
												<RangePicker 
													showTime ={{hideDisabledOptions: true, format : 'YYYY-MM-DD HH'}} 
													style={{ width: '320px', margin:'5px'}}
													defaultValue={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : null}
													format={settingsdateformat + ' ' + settingstimeformat}
													placeholder={[langdata && langdata.Startdateandtime ? langdata.Startdateandtime : 'Start date and time', langdata && langdata.Enddateandtime ? langdata.Enddateandtime : 'End date and time']}
													disabledDate={disabledDate}
													disabledTime={disabledRangeTime}
													onChange={handleSetDate}
													allowClear={true} />
											</Tooltip>
											<Space>&nbsp;</Space>
											<Tooltip placement="bottom" title={langdata && langdata.Pleaseselectfracture ? langdata.Pleaseselectfracture :'Please select fracture'}>
												<Select
													style={{ width: '180px', margin:'5px'}}
													showSearch
													filterOption={filterOption}
													placeholder={langdata && langdata.Selectfracture ? langdata.Selectfracture : 'Select fracture'}
													optionFilterProp="children"
													defaultValuevalue={null}
													value={fractureId ? fractureId : tempFractureid}
													options={fracturelist}
													onSelect={(value)=>handleSetFracture(value)}
												/>
											</Tooltip>
											<Space>&nbsp;</Space>
											<Tooltip placement="bottom" title={langdata && langdata.MicroSeismic ? langdata.MicroSeismic:'Microseismic'}>
												<Button
													style={{ margin:'5px'}}
													type='primary'
													onClick={handleDetail}
												>{langdata && langdata.MicroSeismic ? langdata.MicroSeismic:'Microseismic'}</Button>
											</Tooltip>
										</Col>
									</Row>
								</Col>
							</Row>

							<Card style={{ minHeight: '500px', margin: '10px', padding : '5px' }}>
								<Row style={{
								// margin: '10px',
								// marginBottom : '0px',
								// marginTop : '10px',
								// paddingLeft: '10px',
								// paddingRight: '10px',
								// backgroundColor: '#ffffff',
									alignItems:'center',
								}} >
									<Col span={24} style={{ minHeight : '450px'}}>
										<Spin spinning={microSeismic.loading} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
											<button
												style={{ marginTop : '6px', marginRight : '1px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'microseismicData');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button>
											<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>
												<Plot
													data={microseismicData}
													layout={layoutMicroseismic}
													style={{ width: '100%', height: '450px' , minHeight : '450px'}}
													config={{ 
														responsive: true,
														// displayModeBar: true,
														displaylogo : false,
														modeBarButtonsToRemove: [
															'pan2d',
															'zoom2d',
															'select2d',
															'zoomIn2d',
															'zoomOut2d',
															'autoScale2d',
															'resetScale2d',
															'hoverClosestCartesian',
															'hoverCompareCartesian',
															'zoom3d',
															'pan3d',
															'resetViews3d',
															'hoverClosest3d',
															'hoverCompare3d',
															'orbitRotation',
															'tableRotation',
															'resetCameraDefault3d',
															'resetCameraLastSave3d',
															'lasso2d'
														],
	
													}}
												/>
											</Col>
										</Spin>
									</Col>
								</Row>
							</Card>
							<Card style={{ minHeight: '500px', margin: '10px', padding : '5px' }}>
								<Row style={{
								// margin: '10px',
								// marginBottom : '0px',
								// marginTop : '10px',
								// paddingLeft: '10px',
								// paddingRight: '10px',
								// backgroundColor: '#ffffff',
									alignItems:'center',
								}} >
									<Col span={24} style={{ minHeight : '450px', backgroundColor: '#ffffff'}}>
										<Spin spinning={microSeismic.loading} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>

											<button
												style={{ marginTop : '6px', marginRight : '1px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'fracturelocationMapping');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button>
											<Plot
												data={dotData}
												layout={dotLayout}
												config={{ 
													responsive: true,
													// displayModeBar: true,
													displaylogo : false,
													modeBarButtonsToRemove: [
														'pan2d',
														'zoom2d',
														'select2d',
														'zoomIn2d',
														'zoomOut2d',
														'autoScale2d',
														'resetScale2d',
														'hoverClosestCartesian',
														'hoverCompareCartesian',
														'zoom3d',
														'pan3d',
														'resetViews3d',
														'hoverClosest3d',
														'hoverCompare3d',
														'orbitRotation',
														'tableRotation',
														'resetCameraDefault3d',
														'resetCameraLastSave3d',
														'lasso2d'
													],

												}}
												style={{ width: '100%', height: '450px', minHeight : '450px' }}
											/>
										</Spin>
									</Col>
								</Row>
							</Card>
						</>
							
						:
						graph =='bandpassfilteredHeatmap' ? 
							<Col span={24} style={{padding:'10px'}}>
								<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
									<button
									// style={{ marginTop : '35px', marginRight : '15px'}}
										onClick={()=>{handlefullSize(fullsize === false ? true:false,'bandpassfilteredHeatmap');}} 
										className="graphZoomOutButton" ><FullscreenExitOutlined /></button>

									<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
										// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
										className="wellInfo" >
										{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
										<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
										<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {microSeismic && microSeismic.graphData && microSeismic.graphData.channel_name} <br />
									</p>
									<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>
										<Plot
											data={bandPassPlotData}
											layout={ bandPassLayout}
											style={{ width: '100%', height: '800px' }}
											onClick={(value) => {handleDTSClick(value, 'das');}}
											config={{ 
												responsive: true,
												// displayModeBar: true,
												displaylogo : false,
												modeBarButtonsToRemove: [
													'pan2d',
													'zoom2d',
													'select2d',
													'zoomIn2d',
													'zoomOut2d',
													'autoScale2d',
													'resetScale2d',
													'hoverClosestCartesian',
													'hoverCompareCartesian',
													'zoom3d',
													'pan3d',
													'resetViews3d',
													'hoverClosest3d',
													'hoverCompare3d',
													'orbitRotation',
													'tableRotation',
													'resetCameraDefault3d',
													'resetCameraLastSave3d',
													'lasso2d'
												],
	
											}}
										/>
									</Col>
								</Spin>
							</Col>
							: 
							graph =='microseismicData' ? 
								<>
									<Col span={24} style={{ display : 'flex', justifyContent : 'flex-end', marginRight : '10px', backgroundColor : '#ffffff', padding : '10px', marginLeft : '10px'}}> 
										<Space>&nbsp;</Space>
										<Tooltip placement="bottom" title={langdata && langdata.Filterbychannel ? langdata.Filterbychannel : 'Filter by channel'}>
											<Space>
												<Select
													style={{ width: '200px'}}
													showSearch
													filterOption={filterOption}
													placeholder={langdata && langdata.SelectZone ? langdata.SelectZone : 'Select Zone'}
													optionFilterProp="children"
													defaultValuevalue={null}
													value={channelId ?  channelId : tempchannelId}													
													options={channellist}
													onSelect={(value)=>handleSetChannel(value)}
												/>
											</Space>
										</Tooltip>
										<Space>&nbsp;</Space>
										<Tooltip placement="bottom" title={langdata && langdata.Pleaseselectthedateandtime ? langdata.Pleaseselectthedateandtime :'Please select the date and time'}>
											<RangePicker 
												showTime ={{hideDisabledOptions: true, format : 'YYYY-MM-DD HH'}} 
												style={{ width: '350px', marginLeft : '10px'}}
												defaultValue={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : null}
												format={settingsdateformat + ' ' + settingstimeformat}
												placeholder={[langdata && langdata.Startdateandtime ? langdata.Startdateandtime : 'Start date and time', langdata && langdata.Enddateandtime ? langdata.Enddateandtime : 'End date and time']}
												disabledDate={disabledDate}
												disabledTime={disabledRangeTime}
												onChange={handleSetDate}
												allowClear={true} />
										</Tooltip>
										<Space>&nbsp;</Space>
										<Tooltip placement="bottom" title={langdata && langdata.Pleaseselectfracture ? langdata.Pleaseselectfracture :'Please select fracture'}>
											<Select
												style={{ width: '180px', marginLeft : '10px'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.Selectfracture ? langdata.Selectfracture : 'Select fracture'}
												optionFilterProp="children"
												defaultValuevalue={null}
												value={fractureId ? fractureId : tempFractureid}
												options={fracturelist}
												onSelect={(value)=>handleSetFracture(value)}
											/>
										</Tooltip>
									</Col>
									<Col span={24} style={{padding:'10px'}}>
										<Spin spinning={microSeismic.loading} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
											<button
												style={{ marginTop : '10px', marginLeft : '10px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'microseismicData');}} 
												className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
											<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>
												<Plot
													data={microseismicData}
													layout={layoutMicroseismic}
													config={{ 
														responsive: true,
														// displayModeBar: true,
														displaylogo : false,
														modeBarButtonsToRemove: [
															'pan2d',
															'zoom2d',
															'select2d',
															'zoomIn2d',
															'zoomOut2d',
															'autoScale2d',
															'resetScale2d',
															'hoverClosestCartesian',
															'hoverCompareCartesian',
															'zoom3d',
															'pan3d',
															'resetViews3d',
															'hoverClosest3d',
															'hoverCompare3d',
															'orbitRotation',
															'tableRotation',
															'resetCameraDefault3d',
															'resetCameraLastSave3d',
															'lasso2d'
														],
	
													}}
													style={{ width: '100%', height: '800px' }}
												/>
											</Col>
										</Spin>
									</Col>
								</>
								: 
								graph =='fracturelocationMapping' ? 
									<>
										<Col span={24} style={{ display : 'flex', justifyContent : 'flex-end', marginRight : '10px', backgroundColor : '#ffffff', padding : '10px', marginLeft : '10px'}}> 
											<Space>&nbsp;</Space>
											<Tooltip placement="bottom" title={langdata && langdata.Filterbychannel ? langdata.Filterbychannel : 'Filter by channel'}>
												<Space>
													<Select
														style={{ width: '200px'}}
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.SelectZone ? langdata.SelectZone : 'Select Zone'}
														optionFilterProp="children"
														defaultValuevalue={null}
														value={channelId ?  channelId : tempchannelId}													
														options={channellist}
														onSelect={(value)=>handleSetChannel(value)}
													/>
												</Space>
											</Tooltip>
											<Space>&nbsp;</Space>
											<Tooltip placement="bottom" title={langdata && langdata.Pleaseselectthedateandtime ? langdata.Pleaseselectthedateandtime :'Please select the date and time'}>
												<RangePicker 
													showTime ={{hideDisabledOptions: true, format : 'YYYY-MM-DD HH'}} 
													style={{ width: '350px', marginLeft : '10px'}}
													defaultValue={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : null}
													format={settingsdateformat + ' ' + settingstimeformat}
													placeholder={[langdata && langdata.Startdateandtime ? langdata.Startdateandtime : 'Start date and time', langdata && langdata.Enddateandtime ? langdata.Enddateandtime : 'End date and time']}
													disabledDate={disabledDate}
													disabledTime={disabledRangeTime}
													onChange={handleSetDate}
													allowClear={true} />
											</Tooltip>
											<Space>&nbsp;</Space>
											<Tooltip placement="bottom" title={langdata && langdata.Pleaseselectfracture ? langdata.Pleaseselectfracture :'Please select fracture'}>
												<Select
													style={{ width: '180px', marginLeft : '10px'}}
													showSearch
													filterOption={filterOption}
													placeholder={langdata && langdata.Selectfracture ? langdata.Selectfracture : 'Select fracture'}
													optionFilterProp="children"
													defaultValuevalue={null}
													value={fractureId ? fractureId : tempFractureid}
													options={fracturelist}
													onSelect={(value)=>handleSetFracture(value)}
												/>
											</Tooltip>
										</Col>
										<Col span={24} style={{padding:'10px'}}>
											<Spin spinning={microSeismic.loading} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>

												<button
													style={{ marginTop : '10px', marginLeft : '10px'}}
													onClick={()=>{handlefullSize(fullsize === false ? true:false,'fracturelocationMapping');}} 
													className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
												<Plot
													data={dotData}
													layout={dotLayout}
													config={{ 
														responsive: true,
														// displayModeBar: true,
														displaylogo : false,
														modeBarButtonsToRemove: [
															'pan2d',
															'zoom2d',
															'select2d',
															'zoomIn2d',
															'zoomOut2d',
															'autoScale2d',
															'resetScale2d',
															'hoverClosestCartesian',
															'hoverCompareCartesian',
															'zoom3d',
															'pan3d',
															'resetViews3d',
															'hoverClosest3d',
															'hoverCompare3d',
															'orbitRotation',
															'tableRotation',
															'resetCameraDefault3d',
															'resetCameraLastSave3d',
															'lasso2d'
														],

													}}
													style={{ width: '100%', height: '800px' }}
												/>
											</Spin>
										</Col>
									</>
									: 
									null 
					: 
					<Row span={24} style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={24}>							
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
						</Col>
					</Row>
				}

				<Modal
					title={langdata && langdata.GraphConfigurations ? langdata.GraphConfigurations: 'Graph Configurations' }
					open={isModalOpen} 
					onOk={handleOk} 
					onCancel={handleCancel}
					maskClosable={false}
					footer={[
						<>
							<Row style={{
								margin: '10px',
								paddingLeft: '10px',
								paddingRight: '10px',
								background: '#FFFFFF',
								alignItems: 'center',
								marginTop : '10px'
							}}
							>
								<Col span={8}>
									{/* <Row>
										<Space>
											<Button onClick={handleReset}>RESET</Button>
										</Space>
									</Row> */}
								</Col>
								<Col span={16}>
									<Row justify="end">
										<Col>
											{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>User Type:</Text> */}
											<Space>
												<Button style={{ margin : '5px'}} onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
											</Space>
											<Space>&nbsp;</Space>
											<Space>
												<Button onClick={formik.handleSubmit} type="primary" style={{ margin : '5px'}} >{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button>
											</Space>
										</Col>
									</Row>
								</Col>
							</Row>
						</>
					]}
					width={900}
				>		
					<Divider/>
					<Row gutter={24}>
						<Col span={23}>
							{dasEnabled == true ?
								<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.DAS ? langdata.DAS:'DAS'}</b></Typography>
								: 
								<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.DTS ? langdata.DTS:'DTS'}</b></Typography>
							}
						</Col>
						<Col  justify="end"  span={1}>
							<Tooltip placement="bottom" title={langdata && langdata.Reset ? langdata.Reset : 'Reset'}>
								<Avatar size="small" style={{ backgroundColor : '#1677FF'}}>							
									<ReloadOutlined onClick={handleReset} />	
								</Avatar>
							</Tooltip>
						</Col>
					</Row>
					{dasEnabled == true ? 
						<>
							<Row gutter={24} >
								<Col span={12}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.AmplitudeV ? langdata.AmplitudeV: 'Amplitude (V)'}&nbsp;&nbsp;&nbsp;
										<Tooltip placement="bottom" title={langdata && langdata.Enteramplituderange ? langdata.Enteramplituderange:'Enter amplitude range'}>
											<Input 
												type='number'
												style={{width : '20%', height  :'25px'}}
												disabled = {formik.values.ampThresholdsOverride == true ? false : true}
												value={formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold}
												onChange={(e) => {(e.target.value >= 0 && e.target.value <= 20000) ? formik.setFieldValue('minAmpThreshold', e.target.value) : null;
												}}
											/>
											{' '} <span > -  </span>{' '}
											<Input  
												type='number'
												style={{width : '20%', height  :'25px'}}
												disabled = {formik.values.ampThresholdsOverride == true ? false : true}
												value={formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold}
												onChange={(e) => {(e.target.value >= 0 && e.target.value <= 20000) ? formik.setFieldValue('maxAmpThreshold', e.target.value) : null;
												}}
											/>
										</Tooltip>
									</Typography>
									<Slider 
										style={{marginTop:'20px'}}
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minAmpThreshold', value[0]);formik.setFieldValue('maxAmpThreshold', value[1]);}} 
										marks={ampMarks} 
										disabled = {formik.values.ampThresholdsOverride == true ? false : true}
										value={[formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold, formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold]}/>
								</Col>
								{/* <Col span={8}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.SNRatio ? langdata.SNRatio: 'S/N Ratio'}</Typography>
									<Slider 
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minAmpSNThresholds', value[0]);formik.setFieldValue('maxAmpSNThresholds', value[1]);}} 
										marks={dasSNRatioMarks}
										disabled = {formik.values.ampSNThresholdsOverride == true ? false : true}
										value={[formik.values.ampSNThresholdsOverride == true ? formik.values.minAmpSNThresholds : formik.values.defaultminAmpSNThresholds, formik.values.ampSNThresholdsOverride == true ? formik.values.maxAmpSNThresholds : formik.values.defaultmaxAmpSNThresholds]}/>
								</Col> */}
								<Col span={12}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.CutoffFrequencyHz ? langdata.CutoffFrequencyHz: 'Cutoff Frequency (Hz)'}&nbsp;&nbsp;&nbsp;
										<Tooltip placement="bottom" title={langdata && langdata.Entercutofffrequencyrange ? langdata.Entercutofffrequencyrange:'Enter cutoff frequency range'}>
											<Input 
												type='number'
												style={{width : '20%', height  :'25px'}}
												disabled = {formik.values.ampFreqThresholdsOverride == true ? false : true}
												value={formik.values.ampFreqThresholdsOverride == true ? formik.values.minAmpFreqThresholds : formik.values.defaultminAmpFreqThresholds}
												onChange={(e) => {(e.target.value >= 0 && e.target.value <= 20000) ? formik.setFieldValue('minAmpFreqThresholds', e.target.value) : null;
												}}
											/>
											{' '} <span > -  </span>{' '}
											<Input  
												type='number'
												style={{width : '20%', height  :'25px'}}
												disabled = {formik.values.ampFreqThresholdsOverride == true ? false : true}
												value={formik.values.ampFreqThresholdsOverride == true ? formik.values.maxAmpFreqThresholds : formik.values.defaultmaxAmpFreqThresholds}
												onChange={(e) => {(e.target.value >= 0 && e.target.value <= 20000) ? formik.setFieldValue('maxAmpFreqThresholds', e.target.value) : null;
												}}
											/>
										</Tooltip>
									</Typography>
									<Slider 
										style={{marginTop:'20px'}}
										range step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minAmpFreqThresholds', value[0]);formik.setFieldValue('maxAmpFreqThresholds', value[1]);}} 
										marks={dasFrequencyMarks}
										disabled = {formik.values.ampFreqThresholdsOverride == true ? false : true}
										value={[formik.values.ampFreqThresholdsOverride == true ? formik.values.minAmpFreqThresholds : formik.values.defaultminAmpFreqThresholds, formik.values.ampFreqThresholdsOverride == true ? formik.values.maxAmpFreqThresholds : formik.values.defaultmaxAmpFreqThresholds]}/>
								</Col>
								<Col span={12}>
									<Row>
										<Space>
											<Tooltip  placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch 
													size="small" 
													onClick={(value) => {formik.setFieldValue('ampThresholdsOverride', value);}}
													checked={formik.values.ampThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>
								{/* <Col span={8}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch 
													size="small" 
													onClick={(value) => {formik.setFieldValue('ampSNThresholdsOverride', value);}}
													checked={formik.values.ampSNThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col> */}
								<Col span={12}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch 
													size="small" 
													onClick={(value) => {formik.setFieldValue('ampFreqThresholdsOverride', value);}}
													checked={formik.values.ampFreqThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>
							</Row>
							<Divider/>
						</>
						: null }
					{/* {dtsEnabled == true ?
						<>
							{dasEnabled == true ?
								<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.DTS ? langdata.DTS:'DTS'}</b></Typography>
								: null }
							<Row gutter={24} >
								<Col span={8}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.TemperatureC ? langdata.TemperatureC: 'Temperature (°C)'}</Typography>
									<Slider 
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minTempThreshold', value[0]);formik.setFieldValue('maxTempThreshold', value[1]);}} 
										marks={tempMarks}
										disabled = {formik.values.tempThresholdsOverride == true ? false : true}
										value={[formik.values.tempThresholdsOverride == true ? formik.values.minTempThreshold : formik.values.defaultminTempThreshold, formik.values.tempThresholdsOverride == true ? formik.values.maxTempThreshold : formik.values.defaultmaxTempThreshold]}
									/>
								</Col>
								<Col span={8}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.SNRatio ? langdata.SNRatio:  'S/N Ratio'}</Typography>
									<Slider 
										range 
										step={10} 
										max={20000}  
										onChange={(value) => {formik.setFieldValue('minTempSNThresholds', value[0]);formik.setFieldValue('maxTempSNThresholds', value[1]);}} 
										marks={dtsSNRatioMarks}
										disabled = {formik.values.tempSNThresholdsOverride == true ? false : true}
										value={[formik.values.tempSNThresholdsOverride == true ? formik.values.minTempSNThresholds : formik.values.defaultminTempSNThresholds, formik.values.tempSNThresholdsOverride == true ? formik.values.maxTempSNThresholds : formik.values.defaultmaxTempSNThresholds]}
									/>
								</Col>
								<Col span={8}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.CutoffFrequencyHz ? langdata.CutoffFrequencyHz: 'Cutoff Frequency (Hz)'}</Typography>
									<Slider 
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minTempFreqThresholds', value[0]);formik.setFieldValue('maxTempFreqThresholds', value[1]);}} 
										marks={dtsFrequencyMarks}
										disabled = {formik.values.tempFreqThresholdsOverride == true ? false : true}
										value={[formik.values.tempFreqThresholdsOverride == true ? formik.values.minTempFreqThresholds : formik.values.defaultminTempFreqThresholds, formik.values.tempFreqThresholdsOverride == true ? formik.values.maxTempFreqThresholds : formik.values.defaultmaxTempFreqThresholds]}
									/>
								</Col>
								<Col span={8}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch size="small"
													onClick={(value) => {formik.setFieldValue('tempThresholdsOverride', value);}}
													checked={formik.values.tempThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>
								<Col span={8}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch 
													size="small"
													onClick={(value) => {formik.setFieldValue('tempSNThresholdsOverride', value);}}
													checked={formik.values.tempSNThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>
								<Col span={8}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch 
													size="small"
													onClick={(value) => {formik.setFieldValue('tempFreqThresholdsOverride', value);}}
													checked={formik.values.tempFreqThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>
							</Row>
							<Divider/>
						</>
						: null } */}
					<Row gutter={24} >
						<Col span={24}>
							<Typography style={{ marginTop : '15px'}}>{langdata && langdata.Depth ? langdata.Depth +' (m)' : 'Depth (m)'}&nbsp;&nbsp;&nbsp;
								<Tooltip placement="bottom" title={langdata && langdata.Enterdepthrange ? langdata.Enterdepthrange:'Enter depth range'}>
									<Input 
										type='number' 
										style={{width : '15%', height  :'25px'}}
										value={drawerDepthFrom ? drawerDepthFrom : depthRangeFrom} 
										max={zonefrom} 
										min={zoneto} 
										placeholder={langdata && langdata.DepthRangeFrom ? langdata.DepthRangeFrom: 'Depth Range From'} 
										onChange={handleInputDepthRangefrom} 
									/>
									{' '} <span > -  </span>{' '}
									<Input 
										type='number' 
										style={{width : '15%', height  :'25px'}}
										value={drawerDepthTo ? drawerDepthTo : depthRangeTo} 
										max={zonefrom} min={zoneto} 
										placeholder={langdata && langdata.DepthRangeTo ? langdata.DepthRangeTo: 'Depth Range To'} 
										onChange={handleInputDepthRangeTo}
									/>
								</Tooltip>
							</Typography>
							<Slider style={{marginTop:'20px'}} range max={wellFrom ? wellFrom : tempendDepth} onChange={handleDepthChange} marks={depthMarks} value={[drawerDepthFrom ? drawerDepthFrom : depthRangeFrom, drawerDepthTo ? drawerDepthTo : depthRangeTo]} />
						</Col>
					</Row>
				</Modal>

				<Modal
					title={langdata && langdata.SaveforTraining ? langdata.SaveforTraining : 'Save for Training'}
					open={isDTSDataModalOpen} 
					onOk={handleOk} 
					onCancel={handleCancel}
					maskClosable={false}
					footer={[
						<Space key ='options'>
							<Button style={{ margin : '5px'}} onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
							<Button  type="primary" onClick={handleNavigateSimulation} style={{ margin : '5px'}} >{langdata && langdata.NEXT ? langdata.NEXT : 'NEXT'}</Button>
						</Space>
					]}
					// width={600}
				>
					<Row gutter={24}>
						<Col span={24}>
							{/* <Form.Item label={langdata && langdata.Time ? langdata.Time :  'Time'} >
									<Input value={dtsClickableData && dtsClickableData.x} disabled/>
								</Form.Item> */}
							<b>Time : </b>{dtsClickableData && dtsClickableData.x}
						</Col>
						<Col span={24} style={{ marginTop : '5px'}}>
							{/* <Form.Item label={langdata && langdata.Depth ? langdata.Depth :  'Depth'} >
									<Input value={dtsClickableData && dtsClickableData.y} disabled/>
								</Form.Item> */}
							<b>Depth : </b>{dtsClickableData && dtsClickableData.y}
						</Col>
						<Col span={24} style={{ marginTop : '5px'}}>
							{/* <Form.Item label={langdata && langdata.Temperature ? langdata.Temperature :  'Temperature' + ':'} >
									<Input value={dtsClickableData && dtsClickableData.z} disabled/>
								</Form.Item> */}
							<b>Temperature : </b>{dtsClickableData && dtsClickableData.z}
						</Col>
						<Col span={24} style={{ marginTop : '10px'}}>
							<Form.Item label={langdata && langdata.Label ? langdata.Label :  'Label'} >
								<Select Item
									style={{ width: '360px'}}
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.SelectEvent ? langdata.SelectEvent :'Select Event'}
									optionFilterProp="children"
											
									// defaultValue={['all']}
									options={allevents}
									onSelect={(value, options) => {handleSelectEvent(options);}}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Modal>
			</Content>

			<Spin fullscreen spinning={false} />

			{/* Add Vulnerable Point Details Modal Starts */}
			
			{/* Delete User Modal Ends */}
		</>
	);
}